import angular from 'angular';
import 'angular-ui-bootstrap'

import services from '../../../services'
import OverviewComponent from './overview.component/overview.component';
import PersonTablerowComponent from './person.tablerow.component/person.tablerow.component';
import AlarmGroupsComponent from './alarm.groups.component/alarm.groups.component';
import AlarmmonitorComponent from './alarmmonitor.component/alarmmonitor.component';
import AmobileComponent from './amobile.component/amobile.component';
import ApagerComponent from './apager.component/apager.component.ts';
import GroupSingleSelectComponent from "./group.single.select.component/group.single.select.component";
import ApagerRowComponent from "./apager.tablerow.component/apager.tablerow.component";
import UnitSelectComponent from './unit.select.component/unit.select.component';
import PersonSingleSelectComponent from "./person.single.select.component/person.single.select.component";
import AmobileTablerowComponent from './amobile.tablerow.component/amobile.tablerow.component';
import TrackingComponent from './tracking.component/tracking.component';
import TrackingTablerowComponent from './tracking.component/tracking.component.row/tracking.tablerow.component';
import PersonAlarmGroupsComponent from "./edit.person.alarm_groups.component/edit.person.alarm_groups.component";
import PersonSubstitutesComponent from './edit.person.substitutes.component/edit.person.substitutes.component';
import GeofenceComponent from "./geofence.component/geofence.component";
import GeofenceTablerowComponent from "./geofence.tablerow.component/geofence.tablerow.component";
import PersonPropertiesComponent from "./person.properties.component/person.properties.component";

//import modals from '../../modals';

export default angular.module('FE2.components.addressbook', ['ui.bootstrap', services])
  .directive('tableOverview', () => new OverviewComponent)
  .directive('personRow', () => new PersonTablerowComponent)
  .directive('alarmGroups', () => new AlarmGroupsComponent)
  .directive('tableAlarmmonitor', () => new AlarmmonitorComponent)
  .directive('tableAmobile', () => new AmobileComponent)
  .directive('tableApager', () => new ApagerComponent)
  .directive('groupSingleSelect', () => new GroupSingleSelectComponent)
  .directive('apagerRow', () => new ApagerRowComponent)
  .directive('unitSelect', () => new UnitSelectComponent)
  .directive('personSingleSelect', () => new PersonSingleSelectComponent())
  .directive('amobileTablerow', () => new AmobileTablerowComponent())
  .directive('trackingOverview', () => new TrackingComponent())
    .directive('trackingPersonRow', () => new TrackingTablerowComponent())
  .directive('personAlarmGroups',()=> new PersonAlarmGroupsComponent())
  .directive('personSubstitutes', () => new PersonSubstitutesComponent())
  .directive('geofences', () => new GeofenceComponent())
  .directive('geofencesTablerow', () => new GeofenceTablerowComponent())
  .directive('personProperties', () => new PersonPropertiesComponent)
    .name;
