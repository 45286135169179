import angular from 'angular';
import 'angular-ui-bootstrap'

import services from '../../../services'
// import modals from '../../modals';
import AdminProtocolComponent from "./admin.protocol.component/admin.protocol.component";
import AdminSettingsComponent from "./admin.settings.component/admin.settings.component";
import AdminUsersComponent from "./admin.users.component/admin.users.component";
import AdminRelaisComponent from "./admin.relais.component/admin.relais.component";
import AdminLogsComponent from "./admin.logs.component/admin.logs.component";
import AdminSMSComponent from "./admin.sms.component/admin.sms.component";
import RelaisTablerowComponent from "./admin.relais.tablerow.component/admin.relais.tablerow.component";
import AdminInputComponent from "./admin.input.component/admin.input.component";
import InputTablerowComponent from "./admin.input.tablerow.component/admin.input.tablerow.component";
import AdminDashboardComponent from "./admin.dashboard.component/admin.dashboard.component";
import AdminAddressbookComponent from "./admin.addressbook.component/admin.addressbook.component";
import AdminRevisionComponent from "./admin.revision.component/admin.revision.component";
import AdminPrivacySettingsComponent from "./admin.settings/admin.privacy.settings/admin.privacy.component";
import AdminMapSettingsComponent from "./admin.settings/admin.map.settings/admin.map.component";
import UserVehicleAccessComponent from "./admin.users.vehicles.component/admin.users.vehicles.component";
import AdminUsersLicenceComponent from "./admin.users.licence.component/admin.users.licence.component";
import AdminKeywordsSettingsComponent from "./admin.settings/admin.keywords.settings/admin.keywords.component";
import AdminWebserverSettingsComponent from "./admin.settings/admin.webserver.settings/admin.webserver.component";
import AdminMasterSlaveSettingsComponent from "./admin.settings/admin.masterslave.settings/admin.masterslave.component";
import AdminMailAccountSettingsComponent from "./admin.settings/admin.mailaccount.settings/admin.mailaccount.component";
import AdminOutputPluginSettingsComponent from "./admin.settings/admin.outputplugins.settings/admin.outputplugins.component";
import AdminReplacementsSettingsComponent from "./admin.settings/admin.replacements.settings/admin.replacements.component";
import AdminLogoSettingsComponent from "./admin.settings/admin.logo.settings/admin.logo.component";
import AdminUsersOSComponent from "./admin.users.os.component/admin.users.os.component";
import AdminOutageComponent from "./admin.settings/admin.outage.settings/admin.outage.component";
import AdminUsersAlarmObjectsComponent from "./admin.users.alarmobjects.component/admin.users.alarmobjects.component"
import UserIOBatteryComponent from "./admin.users.iobattery.component/admin.users.iobattery.component"
import UserParkingComponent from "./admin.users.parking.component/admin.users.parking.component"
import AdminFeedbackGroupsSettings from "./admin.settings/admin.feedbackGroups.settings/admin.feedbackGroups.settings";
import AdminSplitterSettingsComponent from "./admin.settings/admin.splitter.settings/admin.splitter.component";
import AdminAddressSettingsComponent from "./admin.settings/admin.address.settings/admin.address.component";
import AdminUsersUnitsComponent from "./admin.users.units.component/admin.users.units.component";
import AdminComingHomeComponent from "./admin.settings/admin.coming.home.settings/admin.coming.home.component";
import AdminControllerComponent from "./admin.controller.component/admin.controller.component";
import AdminRelaisControllerComponent from "./admin.users.relais.component/admin.users.relais.component";
import AdminUsersSettingsComponent from "./admin.users.settings.component/admin.users.settings.component";

export default angular.module('FE2.components.admin', ['ui.bootstrap', services])
  //.controller('MainController', MainController)
  .directive('adminProtocol', () => new AdminProtocolComponent())
  .directive('adminPrivacySettings', () => new AdminPrivacySettingsComponent())
  .directive('adminMapSettings', () => new AdminMapSettingsComponent())
  .directive('adminWebserverSettings', () => new AdminWebserverSettingsComponent())
  .directive('adminMasterslaveSettings', () => new AdminMasterSlaveSettingsComponent())
  .directive('adminSplitterSettings', () => new AdminSplitterSettingsComponent())
  .directive('adminAddressSettings', () => new AdminAddressSettingsComponent())
  .directive('adminMailaccountSettings', () => new AdminMailAccountSettingsComponent())
  .directive('adminOutputpluginsSettings', () => new AdminOutputPluginSettingsComponent())
  .directive('adminSettings', () => new AdminSettingsComponent())
  .directive('adminUsers', () => new AdminUsersComponent())
  .directive('adminVehiclesAccess', () => new UserVehicleAccessComponent())
  .directive('adminIobattery', () => new UserIOBatteryComponent())
  .directive('adminParkingPositions', () => new UserParkingComponent)
  .directive('adminRelaisController', () => new AdminRelaisControllerComponent)
  .directive('adminUserLicence', () => new AdminUsersLicenceComponent())
  .directive('adminRelais', () => new AdminRelaisComponent())
  .directive('adminLogs', () => new AdminLogsComponent())
  .directive('adminSms', () => new AdminSMSComponent())
  .directive('relaisTableRow', () => new RelaisTablerowComponent())
  .directive('adminInput', () => new AdminInputComponent())
  .directive('inputTableRow', () => new InputTablerowComponent())
  .directive('adminDashboard', () => new AdminDashboardComponent())
  .directive('adminAddressbook', () => new AdminAddressbookComponent())
  .directive('adminRevision', () => new AdminRevisionComponent())
  .directive('adminKeywordsSettings', () => new AdminKeywordsSettingsComponent())
  .directive('adminReplacementsSettings', () => new AdminReplacementsSettingsComponent())
  .directive('adminLogoSettings', () => new AdminLogoSettingsComponent())
  .directive('adminUserOs', () => new AdminUsersOSComponent())
  .directive('adminUserAlarmobjects', () => new AdminUsersAlarmObjectsComponent())
  .directive('adminOutageSettings', () => new AdminOutageComponent())
  .directive('adminFeedbackGroups', () => new AdminFeedbackGroupsSettings())
  .directive('adminUserUnits', () => new AdminUsersUnitsComponent())
  .directive('adminComingHomeSettings', () => new AdminComingHomeComponent())
  .directive('adminControllers', () => new AdminControllerComponent())
  .directive('adminUserSettings', () => new AdminUsersSettingsComponent())
  .name;
