export interface Roadblock {
    id: string;
    name: string;
    routeShape: number[][];
    from: string;
    to: string;
    street: string;
    city: string;
    postalCode: string;
    note: string;
    type: string;
    parent: string;
    userId: string;
    publicAccessible: boolean;
    roadblockAccess: string;
    lastChanged: any;
    important: boolean;
    validRouteShape: boolean;
    idMapTrip: string;
    source: string;
    sourceId: string;
    mapTripType: EMapTripType;
    mapTripDirection: EMapTripDirection;
    mapTripHeight: number;
    mapTripWidth: number;
    mapTripLength: number;
    mapTripWeight: number;
}

export enum EMapTripType {
    BLOCK = 'BLOCK',
    AVOID = 'AVOID'
}

export enum EMapTripDirection {
    ATOB = 'ATOB',
    BTOA = 'BTOA',
    BOTH = 'BOTH'
}