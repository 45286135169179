'use strict'

import { ILogService, IRootScopeService, IScope } from "angular";
import { AlarmDataSimple, AlarmVehicle } from "../../../../data/alarm.data";
import { Emergency } from "../../../../data/emergency.data";
import RestService from "../../../../services/rest.service";
import MapService from "../../../../services/map.service";
import * as L from 'leaflet';
import PrivilegeService from "../../../../services/privilege.service";

require("./mission.modal.css");

/* @ngInject */
export default class MissionModalController {

    public emergency: Emergency;
    public alarmDataForMap: any;

    private map: L.Map;

    private listeners = [];

    public mode: MissionModalMode = MissionModalMode.ALARM;
    private NO_VALUE: string = "Keine Daten";

    constructor(private $scope: IScope,
        private $rootScope: IRootScopeService,
        private $log: ILogService,
        private $uibModalInstance: any,
        public $uibModal: any,
        private restService: RestService,
        private $translate: any,
        private externalId: string,
        private mapService: MapService,
        public privilegeService: PrivilegeService) {

        this.loadEmergency(externalId);

        this.$translate(['MISSIONS.NO_VALUE']).then((translations) => {
            this.NO_VALUE = translations['MISSIONS.NO_VALUE'];
            this.$scope.$applyAsync();
        });

        this.initListeners();
    }

    private loadEmergency(externalId: string) {
        this.restService.getEmergencyByExternalId(externalId)
            .then(emergency => {
                if(emergency.externalId == null) { // all alarms for this emergency were deleted, thus we don't have any data remaining
                    this.$uibModalInstance.close();
                    return;
                }
                this.emergency = emergency;
                this.alarmDataForMap = this.getAlarmDataForMap();
                this.emergency.vehicles = this.distinctVehicles(this.emergency.vehicles); // TODO: keep this?
                this.buildMapImage();
            })
            .then(() => this.$scope.$applyAsync());
    }

    initListeners() {
        //Wait for new alarms
        this.listeners.push(this.$rootScope.$on('new.alarm', (event, alarm: AlarmDataSimple) => {
            this.loadEmergency(this.externalId);
        }));

        // Wait for deletion of an alarm
        this.listeners.push(this.$rootScope.$on('single.alarm.deleted', (event, alarm: AlarmDataSimple) => {
            this.loadEmergency(this.externalId);
        }));

        // Unregister
        this.$scope.$on('$destroy', () => {
            //Each listener has a unregister function. They are stored in listeners array
            this.listeners.forEach((listener) => {
                listener();
            });
        });
    }

    /**
     * makes given list of vehicles distinct by id and uses the values with the oldest timestamp in case of duplicates
     * @returns a new list with distinct values
     */
    distinctVehicles(vehicles: AlarmVehicle[]): AlarmVehicle[] {
        let map = {};
            for(let vehicle of vehicles) {
                const key = vehicle.name;
                let current = map[key];
                if(!current) {
                    map[key] = vehicle;
                    continue;
                }
                if(!current.hasVehicleId && vehicle.hasVehicleId) {
                    map[key] = vehicle;
                    continue;
                }
                if(vehicle.timestamp < current.timestamp) {
                    map[key] = vehicle;
                }
            }
            return Object.values(map);
        }

    buildMapImage() {
        let lat = this.emergency.lat;
        let lng = this.emergency.lng;
        if(!lat || !lng) return;
        let coords = [lat, lng];

        this.map = L.map("header-map", {
            zoomControl: false,
            dragging: false,
            doubleClickZoom: false,
            boxZoom: false
        });

        // calculate center point with offset
        let zoom = 16;
        let x_offset = 200;
        let targetPoint = this.map.project(coords, zoom).subtract([x_offset, 0]);
        let center = this.map.unproject(targetPoint, zoom);

        this.map.setView(center, zoom);

        let layers = this.mapService.getBaseLayers();
        let selectedLayer = this.mapService.getSelectedLayer();
        if(selectedLayer == undefined || layers[selectedLayer] == undefined) {
            selectedLayer = "OpenStreetMap";
        }
        layers[selectedLayer].addTo(this.map);

        L.Icon.Default.imagePath = '/img/static'; // missing setup

        L.marker(coords, { draggable: false }).addTo(this.map);

    }

    cancel() {
        this.$uibModalInstance.close();
    }

    parseDate(): Date {
        if (!this.emergency) return new Date();
        return new Date(this.emergency.timestampString);
    }


    public valueOrTranslation(value: string) {
        if (value) return value;
        return this.NO_VALUE;
    }

    public getAlarmDataForMap() {
        let ad = {
            parameters: {}
        };
        if (this.emergency.location_dest) ad.parameters["location_dest"] = this.emergency.location_dest;
        if (this.emergency.lat) ad.parameters["lat"] = this.emergency.lat;
        if (this.emergency.lng) ad.parameters["lng"] = this.emergency.lng;
        return ad;
    }

}

export enum MissionModalMode {
    ALARM = "ALARM",
    ALARMS = "ALARMS",
    MAP = "MAP",
    LOCATION_MAP = "LOCATION_MAP",
    VEHICLES = "VEHICLES"
}