export interface CalendarAccess {
  calendar: Calendar;
  personNames: Array<DatabasePersonWithAccess>;
  alarmGroups: Array<AlarmGroupAccess>;
}

export interface AlarmGroupAccess {
  selected: boolean;
  name: string;
  alarmGroupId: string;
  color: string;
}

export interface DatabasePersonWithAccess {
  name: string;
  identifier: string;
  personID: string;
  accessPermission: EAccessPermission;
  feedbackPermission: EFeedbackPermission;
}

export interface Calendar {
  id: string;
  fe2UserId: string; // Id containing FE2 User Id
  name: string;
  color: string;
  defaultColor: string;
  type: ECalendarType;
  defaultMaxParticipants: number;
  defaultMinParticipants: number;
  defaultResponsiblePerson: string; // better use Person identifier here instead of plain text name
  defaultStartTime: string;
  defaultEndTime: string;
  defaultWithFeedback: boolean;
  osId: number;
  icalActive: boolean;
  publicIcalUrl: string;
  externalIcalUrl: string;
  icalSyncCalendar: boolean;
  personalIcalUrl: string;
}

export interface CalendarSimple {
  id: string;
  name: string;
  color: string;
  type: ECalendarType;
  defaultColor: string;
  defaultResponsiblePerson: string;
  icalSyncCalendar: boolean;
}

export enum ECalendarType {
  LESSON = 'LESSON', // Unterricht
  TRAINING = 'TRAINING', // Übung
  COURSE = 'COURSE', // Lehrgang
  YOUTH = 'YOUTH', // Jugendfeuerwehr
  CHILD = 'CHILD', // Kinderfeuerwehr
  ASSOCIATION = 'ASSOCIATION', // Verein
  GENERAL = 'GENERAL' // Allgemein
}

export enum EAccessPermission {
  NONE = 'NONE', READ = 'READ', READ_WRITE = 'READ_WRITE'
}

export enum EFeedbackPermission {
  NONE = 'NONE', SIMPLE = 'SIMPLE', FULL = 'FULL'
}