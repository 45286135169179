import RestService from "../../../../services/rest.service";
import { IScope } from "angular";
import { User } from "../../../../data/admin.data";
import angular = require("angular");
import PrivilegeService from "../../../../services/privilege.service";
import { UserSettingsAsAdminRequest } from "../../../../data/user.data";

require('./admin.users.settings.component.scss')

export default class AdminUsersSettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E'
    this.template = require('./admin.users.settings.component.html');
    this.scope = {
      user: '=',
    };
    this.controller = AdminUserSettingsController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}
/* @ngInject */
class AdminUserSettingsController {
  public restService: RestService;
  public $scope: IScope;
  public user: User;
  public isLoading: boolean = false;

  constructor(restService: RestService, $scope: angular.IScope, public privilegeService: PrivilegeService) {
    this.restService = restService;
    this.$scope = $scope;

  }

  saveSettings() {
    this.isLoading = true;
    const request = {
      accessToSmsGatewayAllowed: false
    } as UserSettingsAsAdminRequest;
    this.restService.updateOrganisationSettingsForAdmin(this.user.id, request).catch(() => {
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }
}