'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import angular = require("angular");
import { UserAccount } from "../../../../data/account.data";
import { AlarmRequestSimple, AlarmSendData, Keyword, SearchObjectResponse } from "../../../../data/alarm.data";
import { Person, PersonQuickEditResponse } from "../../../../data/person.data";
import {RolePrivilege} from "../../../../data/privileges.enum";
import { AlarmTemplate } from "../../../../data/template.data";
import { Unit, UnitFilterEntry, UnitFilterResponse } from "../../../../data/unit.data";
import {Vehicle, VehicleSimple, VehiclesSimplePaginatedResponse} from "../../../../data/vehicles.data";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";
import { SortParams } from "../../../views/addressbook.view.component/addressbook.view.component";

require('./alarm.component.css');

//sendAlarmView
export default class AlarmComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E'
    this.template = require('./alarm.component.html');
    this.scope = {
      account: '='
    };
    this.controller = AlarmComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AlarmComponentController {
  public $scope: IScope;
  public $translate: any;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public restService: RestService;
  public helperService: any;
  public dataService: any;
  public Notification: any;
  public $stateParams: any;
  public $uibModal: any;
  public status = {
    showMisc: false
  } as UIStatus;
  public quickEdit: Person[] = [];
  public misc = {
    subric: '',
    withStatistic: true,
    generateExternalId: false
  } as SelectedOptions;

  public atLeastOneUnitSelected = false;
  public quickEditActive = false;

  public isLoadingPersons = false;
  public params = {
    sortType: 'displayName',
    sortReverse: false,
    searchFilter: '',
    currentPage: 0,
    totalElements: 0,
    pageSize: 20
  } as SortParams;
  public addressbook: PersonQuickEditResponse;

  // Vehicles
  public vehicles: VehiclesSimplePaginatedResponse;
  public selectedVehicles: Vehicle[] = [];
  public isLoadingVehicles = false;
  public vehicleParams = {
    searchFilter: '',
    currentPage: 0,
    totalElements: 0,
    pageSize: 20
  } as SortParams;
  public selectedUserIds: string[] = [];



  public isSecureButtonSelected = false;
  public personInQuickAlarmWhichIsNotAvailable = false;
  public isLoading = false;
  public isSearchLoading = false;

  public lat: number;
  public lng: number;
  public onlineservice: any;
  public account: UserAccount;

  public units: Unit[] = [];
  public unitsForAlarm: Unit[] = [];
  public unitsNotForAlarm: Unit[] = [];

  public text: string;
  public selectedKeyword: string;
  public keyword: Keyword;
  public selectedKeywordDescription: string;
  public selectedStreet: string;
  public selectedHouse: string;
  public selectedCity: string;
  public selectedPostalCode: string;
  public selectedAbbreviation: string;
  public selectedBuilding: string;
  public building: any;
  public address: any;

  public listeners = [];

  public allUsers: UnitFilterResponse;
  public selectedUser: UnitFilterEntry;
  public searchFilter = '';

  public selectedTemplate: AlarmTemplate;

  constructor($scope: IScope, $rootScope: IRootScopeService, $translate, restService: RestService, helperService, dataService, $log: ILogService, Notification, $uibModal, $stateParams,
    public privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$translate = $translate;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.restService = restService;
    this.helperService = helperService;
    this.dataService = dataService;
    this.Notification = Notification;
    this.$stateParams = $stateParams;
    this.$uibModal = $uibModal;

    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });

    this.init();
  }

  /**
   * Init data model
   */
  init() {
    this.$log.debug('Init alarm.component...');

    this.searchForUnit().then(() => {
      for (var unit of this.selectedUser.units) {
        if (this.$stateParams.code) {
          if (unit.code === this.$stateParams.code) {
            this.addOrRemoveUnitToAlarm(unit);
            break;
          }
        }
      }
    });
  };

  loadVehicles() {
    this.isLoadingVehicles = true;
    this.restService.loadVehiclesPaginatedSimple(this.vehicleParams.currentPage === 0 ? 0 : this.vehicleParams.currentPage - 1,
      this.vehicleParams.pageSize,
      this.vehicleParams.searchFilter,
      'ASC',
      this.selectedUserIds).then(vehicles => {
        this.vehicles = vehicles;
      }).finally(() => {
        this.isLoadingVehicles = false;
        this.$scope.$applyAsync();
      })
  }

  /**
   * Search and load all units for all users (if available)
   */
  searchForUnit() {
    return new Promise<void>((resolve, reject) => {
      this.isSearchLoading = true;
      this.restService.searchForUnits(this.searchFilter).then(result => {
        this.allUsers = result;
        if (!this.selectedUser) {

          if (this.account) {
            var currentUser = this.allUsers.users.filter(usr => usr.username === this.account.username);
            if (currentUser.length > 0) {
              // Select current user as default
              this.selectUser(currentUser[0]);
            } else {
              this.selectUser(this.allUsers.users[0]);
            }
          } else {
            this.selectUser(this.allUsers.users[0]);
          }

        }
      }).finally(() => {
        this.isSearchLoading = false;
        this.$scope.$applyAsync();
        resolve();
      });
    });
  }

  /**
   * Reset search
   */
  resetSearchAndReload() {
    this.searchFilter = '';
    this.searchForUnit();
  }

  /**
   * Rest vehicle search
   */
  resetVehicleSearchAndReload() {
    this.vehicleParams.searchFilter = '';
    this.loadVehicles();
  }

  personIsInQuickEdit(person: Person) {
    return this.quickEdit.filter(entry => entry.personID === person.personID).length > 0;
  }


  /**
   * Add person to list of quick edits
   * @param person
   */
  addToQuickEdit(person: Person) {

    const alreadyAdded = this.personIsInQuickEdit(person);

    if (alreadyAdded) {
      const addedPerson = this.quickEdit.filter(entry => entry.personID === person.personID)[0];
      this.quickEdit.splice(this.quickEdit.indexOf(addedPerson), 1);
    } else {
      this.quickEdit.push(person);
    }

    // Check if any of selected persons is not available
    this.personInQuickAlarmWhichIsNotAvailable = false;
    if (angular.isDefined(this.onlineservice)) {

      this.quickEdit.forEach(person => {
        var availability = person.availability;
        if ('NOT_AVAILABLE' === availability) {
          this.personInQuickAlarmWhichIsNotAvailable = true;
        }
      });
    }
  };

  resetSearchAndReloadPersonsQuickEdit(){
    this.params.searchFilter = '';
    this.searchPersonsQuickEdit();

  }

  searchPersonsQuickEdit(){
    this.params.currentPage = 0;
    this.pageChanged();
  }

  /**
   * Checks if at least one unit has quick edit active
   */
  updateQuickEditState() {
    if (!this.privilegeService.has(RolePrivilege.Alarm_Quickedit)) {
      return;
    }
    this.atLeastOneUnitSelected = this.unitsForAlarm.length > 0;
    this.quickEditActive = false;
    for (var i = 0; i < this.unitsForAlarm.length; i++) {
      if (this.unitsForAlarm[i].hasQuickEdit) {
        this.quickEditActive = true;
        break;
      }
    }

    if (!this.quickEditActive && this.quickEdit.length > 0) {
      // Reset quick edit
      this.quickEdit = [];
      this.personInQuickAlarmWhichIsNotAvailable = false;
    }

    if (this.quickEditActive && !this.addressbook) {
      // Load persons
      this.pageChanged();
    }
  };

  /**
    Add or remove a unit to the current alarm. Only if user is admin
  */
  addOrRemoveUnitToAlarm(unit: Unit) {
    const filtered = this.unitsForAlarm.filter(u => u.code === unit.code);
    if (filtered.length !== 0 || unit.settings.disabled) {
      this.unitsForAlarm.splice(this.unitsForAlarm.indexOf(filtered[0]), 1);
      this.unitsNotForAlarm.push(unit);
    } else {
      this.unitsForAlarm.push(unit);
      const filtered = this.unitsNotForAlarm.filter(u => u.code === unit.code);
      this.unitsNotForAlarm.splice(this.unitsNotForAlarm.indexOf(filtered[0]), 1);
    }

    this.atLeastOneUnitSelected = this.unitsForAlarm.length > 0;
    this.updateQuickEditState();

    const oldUserIds = this.selectedUserIds;
    this.selectedUserIds = [];
    this.unitsForAlarm.forEach(unit => {
      const userId = unit.userId;
      if (!this.selectedUserIds.includes(userId)) {
        this.selectedUserIds.push(userId);
      }
    });

    const intersection = oldUserIds.filter(element => this.selectedUserIds.includes(element));

    if (oldUserIds.length != this.selectedUserIds.length || intersection.length !== 0) {
      // Trigger reload
      this.loadVehicles();
    }
  };

  /**
   * Select/Deselect a vehicle
   * @param vehicle
   */
  selectVehicle(vehicle: Vehicle) {
    const filtered = this.selectedVehicles.filter(v => v.id === vehicle.id);
    if (filtered.length > 0) {
      this.selectedVehicles.splice(this.selectedVehicles.indexOf(filtered[0]), 1);
    } else {
      this.selectedVehicles.push(vehicle);
    }
  }

  /*
    If unit is selected for alarm
  */
  isUnitSelected(unit: Unit) {
    return this.unitsForAlarm.includes(unit);
  };


  /*
    If person is selected for alarm
  */
  isPersonSelected(person: Person) {
    return this.quickEdit.includes(person);
  };

  /**
   * Returns true if vehicle is selected
   */
  isVehicleSelected(vehicle: Vehicle) {
    return this.selectedVehicles.filter(v => v.id === vehicle.id).length > 0;
  }

  /**
   * Choose alarm on map
   */
  chooseOnMap() {
    this.$uibModal.open({
      template: require('../../../modals/alarms/choose.on.map.modal/choose.on.map.modal.html'),
      controller: 'ChooseAlarmOnMapController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        coords: () => {
          return {
            lat: this.lat,
            lng: this.lng
          }
        },
        okFunction: () => {
          return (coords) => {
            this.lat = coords.lat;
            this.lng = coords.lng;
          }
        }
      }
    });

  };

  /**
   * Load or search for persons
   * @param search
   * @returns
   */
  pageChanged(search?: boolean) {
    if (search) {
      this.params.currentPage = 1;
    }
    return new Promise<void>((resolve, reject) => {
      this.isLoadingPersons = true;
      this.restService.loadPersonsForQuickEdit(this.params.currentPage === 0 ? 0 : this.params.currentPage - 1, this.params.searchFilter, this.params.sortReverse ? 'DESC' : 'ASC', this.params.pageSize, 'aPagerPro', true)
        .then((result: PersonQuickEditResponse) => {
          this.addressbook = result;
          this.params.totalElements = this.addressbook.totalElements;
          this.params.totalPages = this.addressbook.totalPages;
        }).finally(() => {
          this.isLoadingPersons = false;
          this.$scope.$applyAsync();
          resolve();
        });
    });
  }

  //#####
  //Alarm
  //#####

  sendAlarm() {
    this.isLoading = true;
    this.isSecureButtonSelected = false;

    //Gather all data
    var alarm: AlarmRequestSimple = {
      data: {} as AlarmSendData,
      units: '',
      vehicles: [],
      externalId: ''
    };


    alarm.data.message = this.text;
    //Keyword
    if (this.selectedKeyword) {
      alarm.data.keyword = this.selectedKeyword;
    }
    //Keyword
    if (this.keyword) {
      if (this.keyword.additonal) alarm.data.keyword_misc = this.keyword.additonal;
      if (this.keyword.category) alarm.data.keyword_category = this.keyword.category;
      if (this.keyword.ident) alarm.data.keyword_ident = this.keyword.ident;
      if (this.keyword.color) alarm.data.keyword_color = this.keyword.color;
      if (this.keyword.ser) alarm.data.keyword_ser = this.keyword.ser;
    }
    //Description
    if (this.selectedKeyword) {
      alarm.data.keyword_description = this.selectedKeywordDescription;
    }
    //Street
    if (this.selectedStreet) {
      alarm.data.street = this.selectedStreet;
    }
    //House
    if (this.selectedHouse) {
      alarm.data.house = this.selectedHouse;
    }
    //City
    if (this.selectedCity) {
      alarm.data.city = this.selectedCity;
    }
    //PostalCode
    if (this.selectedPostalCode) {
      alarm.data.postalCode = this.selectedPostalCode;
    }
    //Abbreviation
    if (this.selectedAbbreviation) {
      alarm.data.city_abbr = this.selectedAbbreviation;
    }

    //Building
    if (this.selectedBuilding) {
      alarm.data.building = this.selectedBuilding;
    }

    if (this.lat && this.lat > 0 && this.lng && this.lng > 0) {
      alarm.data.lat = this.lat;
      alarm.data.lng = this.lng;
    }

    if (this.building) {
      alarm.data.building = this.building.building;

      if (this.building.additional) {
        alarm.data.location_additional = this.building.additional;
      }

      if (this.building.abbreviation) {
        alarm.data.city_abbr = this.building.abbreviation;
      }

      if (this.building.id) {
        alarm.data.building_id = this.building.id;
      }

      if (this.building.ident) {
        alarm.data.building_ident = this.building.ident;
      }

      if (this.building.alarmObjectType) {
        alarm.data.building_type = this.building.alarmObjectType;
      }

      if (this.building.lat && this.building.lat > 0 && this.building.lng && this.building.lng > 0) {
        alarm.data.lat = this.building.lat;
        alarm.data.lng = this.building.lng;
      }

    }

    alarm.data.withStatistic = this.misc.withStatistic;
    alarm.data.generateExternalId = this.misc.generateExternalId;
    if (this.misc.subric !== '') {
      alarm.data.subric = this.misc.subric;
    }
    alarm.data.alarmType = 'MANUAL';


    if (this.quickEdit.length > 0) {
      //Quickedit
      alarm.data.quickEditActive = 'true';
      var ids = '';
      for (var i = 0; i < this.quickEdit.length; i++) {
        ids = ids + this.quickEdit[i].personID;
        if (i < this.quickEdit.length) {
          ids = ids + ';';
        }
      }
      alarm.data.quickEditIDs = ids;
    }



    //Units
    var units = '';
    for (var ii = 0; ii < this.unitsForAlarm.length; ii++) {
      units = units + this.unitsForAlarm[ii].code;
      if (ii < this.unitsForAlarm.length - 1) {
        units = units + ';';
      }
    }
    alarm.units = units;

    this.selectedVehicles.forEach(vehicle => alarm.vehicles.push(
      {
        id: vehicle.id,
        name: vehicle.name,
        shortName: vehicle.shortName,
        status: vehicle.status,
        statusColor: vehicle.statusColor
      } as VehicleSimple)
    );

    this.restService.sendAlarm(alarm,
      () => {
        this.isLoading = false;
        //Show popup
        this.$translate(['ALARM.SUCCESS_MESSAGE', 'ALARM.SUCCESS_TITLE']).then((translations) => {
          this.Notification.success({
            message: translations['ALARM.SUCCESS_MESSAGE'],
            title: translations['ALARM.SUCCESS_TITLE']
          });
        });
      },
      (response) => {
        //Error occured
        this.isLoading = false;
        this.$log.error(response);
      });

  };

  //#####
  //Typeahead
  //#####

  /**
    Get all available cities
  */
  getCities(val: string) {
    return this.restService.getCities(val);
  };

  /**
    Get all available streets
  */
  getStreets(val: string) {
    if (!this.selectedCity || this.selectedCity === '') {
      return;
    }

    return this.restService.getStreets(this.selectedCity, val);
  };

  /**
    A street has been selected
  */
  streetSelected($item) {
    //Set postalcode
    if ($item.postalCode) {
      this.selectedPostalCode = $item.postalCode;
    }
    if ($item.abbreviation) {
      this.selectedAbbreviation = $item.abbreviation;
    }
    this.address = $item;
  };

  /**
    Get all available buildings
  */
  getBuildings(val: string) {
    return this.restService.getBuildings(val);
  };

  /**
    A building has been selected
  */
  buildingSelected($item: SearchObjectResponse) {
    //Set postalcode, city and street
    if ($item.postalCode) {
      this.selectedPostalCode = $item.postalCode;
    }
    if ($item.street) {
      this.selectedStreet = $item.street;
    }
    if ($item.city) {
      this.selectedCity = $item.city;
    }
    if ($item.house) {
      this.selectedHouse = $item.house;
    }
    if ($item.latitude && $item.latitude > 0 && $item.longitude && $item.longitude > 0) {
      this.lat = $item.latitude;
      this.lng = $item.longitude;
    }

    this.building = $item;
  };

  resetSelectedBuilding(){
    if (!this.selectedBuilding || this.selectedBuilding === '') {
      if (this.building) {
            this.lat = undefined;
            this.lng = undefined;
            this.building = {};
      }
    }
  }

  /**
    Get all available keywords
  */
  getKeywords(val: string) {
    return this.restService.getKeywords(val);
  };

  selectUser(user: UnitFilterEntry) {
    this.selectedUser = user;
  }

  /**
    Get all available keywords searched by description
  */
  getKeywordsByDescription(val: string) {
    return this.restService.getKeywordsByDescription(val);
  };

  changeKeyword() {
    if (this.selectedKeyword === '') {
      this.selectedKeywordDescription = '';
      this.keyword = undefined;
    }
  };

  /**
    A keyword has been selected
  */
  keywordSelected($item: Keyword) {
    //Set postalcode, city and street
    if ($item.description) {
      this.selectedKeywordDescription = $item.description;
    }
    if ($item.keyword) {
      this.selectedKeyword = $item.keyword;
    }
    this.keyword = $item;
  };

  /**
   * Select a template
   */
  selectTemplate(template: AlarmTemplate) {
    // Reset
    for (let i = 0; i < this.unitsForAlarm.length; i++) {
      this.unitsNotForAlarm.push(this.unitsForAlarm[i]);
    }
    this.unitsForAlarm = [];
    //Message
    if (template.message) {
      this.text = template.message;
    }
    //Keyword
    if (template.keyword) {
      this.selectedKeyword = template.keyword;
    }
    //Keyword
    if (template.keyword_misc) {
      if (!this.keyword) {
        this.keyword = { } as Keyword;
      }
      this.keyword.additonal = template.keyword_misc;
    }
    //Description
    if (template.keyword_description) {
      this.selectedKeywordDescription = template.keyword_description;
    }
    //Street
    if (template.street) {
      this.selectedStreet = template.street;
    }
    //House
    if (template.house) {
      this.selectedHouse = template.house
    }
    //City
    if (template.city) {
      this.selectedCity = template.city;
    }
    //PostalCode
    if (template.postalCode) {
      this.selectedPostalCode = template.postalCode;
    }
    //Abbreviation
    if (template.city_abbr) {
      this.selectedAbbreviation = template.city_abbr;
    }

    //Building
    if (template.building) {
      this.building = {
        building: template.building,
        lat: template.lat,
        lng: template.lng,
        additional: template.location_additional
      };
      if (template.building_id) {
        this.building.building_id = template.building_id;
      }
      this.selectedBuilding = template.building;
    }

    template.units.forEach(unit => {
      this.allUsers.users.forEach(user => {
        const unitInList = user.units.filter(u => u.id === unit.id);
        if (unitInList.length > 0) {
          this.addOrRemoveUnitToAlarm(unitInList[0]);
        }
      });

    });

    this.selectedTemplate = template;
  };

  getTemplates(name: string) {
    return this.restService.searchForAlarmTemplates(name)
  }

  resetTemplate() {
    this.selectedTemplate = null;
    // Reset
    for (let i = 0; i < this.unitsForAlarm.length; i++) {
      this.unitsNotForAlarm.push(this.unitsForAlarm[i]);
    }
    this.unitsForAlarm = [];
    this.text = null;
    this.selectedKeyword = null;
    this.keyword = {} as Keyword;
    this.keyword.additonal = null;
    this.selectedKeywordDescription = null;
    this.selectedStreet = null;
    this.selectedHouse = null;
    this.selectedCity = null;
    this.selectedPostalCode = null;
    this.selectedAbbreviation = null;
    this.building = {};
    this.selectedBuilding = null;
  }

}


interface UIStatus {
  showMisc: boolean
}

interface SelectedOptions {
  withStatistic: boolean,
  subric: string,
  generateExternalId: boolean
}