'use strict';

import {ILogService, IRootScopeService, IScope} from 'angular';
import {UserAccount} from '../../../../data/account.data';
import {AlarmGroup, AlarmGroupSimple} from '../../../../data/alarmgroup.data';
import {
  PersonsAddressbookResponse,
  PersonSimple,
  DatabasePersonProvisioningRepresentation,
  PaginatedDatabasePersonProvisioningRepresentation
} from '../../../../data/person.data';
import {ProvisioningResponse, ProvisioningSimple} from '../../../../data/provisioning.data';
import RestService from '../../../../services/rest.service';
import {SortParams} from '../../../views/addressbook.view.component/addressbook.view.component';
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";

require('./assign.provisioning.modal.css');

/**
 * Provision persons with given Provisioning configuration
 *
 */
//AssignProvisioningCtrl
/* @ngInject */
export default class AssignProvisioningModalController {
  public $scope: IScope;
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public restService: RestService;
  public $uibModalInstance: any;
  public dataService: any;
  public provisioningProfile: ProvisioningSimple;
  public addressbook: PaginatedDatabasePersonProvisioningRepresentation;
  public allSelected: boolean = false;
  public selectedPersons: DatabasePersonProvisioningRepresentation[] = [];
  public selectedAlarmGroups: AlarmGroupSimple[] = [];
  public alarmGroups: AlarmGroupSimple[] = [];
  public account: UserAccount;
  public params: SortParams;
  public isLoading = false;
  public isProvisioningGroups = false;
  public useAutoMode = true;
  public priv: PrivilegeService;

  public activeProvisionings: Map<DatabasePersonProvisioningRepresentation, boolean> = new Map<DatabasePersonProvisioningRepresentation, boolean>();
  public results: Map<DatabasePersonProvisioningRepresentation, ProvisioningResponse> = new Map<DatabasePersonProvisioningRepresentation, ProvisioningResponse>();

  constructor($scope: IScope, $log: ILogService, $rootScope: IRootScopeService, $uibModalInstance, dataService, restService: RestService, provisioningProfile: ProvisioningSimple, privilegeService:PrivilegeService) {
    this.$scope = $scope;
    this.$log = $log;
    this.$rootScope = $rootScope;
    this.$uibModalInstance = $uibModalInstance;
    this.dataService = dataService;
    this.account = dataService.getAccount();
    this.restService = restService;
    this.priv= privilegeService;

    this.provisioningProfile = provisioningProfile;

    this.params = {
      sortType: 'displayName', // set the default sort type
      sortReverse: false, // set the default sort order
      searchFilter: '', // set the default search/filter term
      currentPage: 0,
      totalElements: 0,
      pageSize: 20
    } as SortParams;

    // Load all alarm groups
      this.isLoading= true;
      this.restService.loadAlarmGroupsSimpleProvisioning().then(alarmGroups => {
        this.$log.debug(`Loaded ${alarmGroups.length} alarm groups`);
        this.alarmGroups = alarmGroups;
      }).catch(error => {
        this.alarmGroups = [];
        this.$log.error(error);
      }).finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });


    this.pageChanged();
  }

  /**
 * Change the page
 * @returns A promise which resolves after changing the page
 */
  pageChanged(search?: boolean) {
    if (search) {
      this.params.currentPage = 1;
    }
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.restService.loadPersonsProvisionRep(this.params.currentPage === 0 ? 0 : this.params.currentPage - 1, this.params.searchFilter, this.params.sortReverse ? 'DESC' : 'ASC', this.params.pageSize, 'aPagerPro', true)
        .then((result: PaginatedDatabasePersonProvisioningRepresentation) => {
          this.addressbook = result;
          this.params.totalElements = this.addressbook.totalElements;
          this.params.totalPages = this.addressbook.totalPages;
          // check on each load of page if the entries are selected (switching pages back and forth)
          this.checkAllSelected();
        }).finally(() => {
          this.isLoading = false;
          this.$scope.$applyAsync();
          resolve();
        });
    });
  }

  private checkAllSelected() {
    for(let person of this.addressbook.content){
      if (this.selectedPersons.some(personSelected => personSelected.id === person.id)) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
        // stop check, one entry wasn't selected
        break;
      }
    }
  }

  resetSearchAndReload() {
    this.params.searchFilter = '';
    this.pageChanged();
  };

  isMobile(person: DatabasePersonProvisioningRepresentation): boolean {
    return !person.apager.includes('@');
  }

  isProvisioning(person: DatabasePersonProvisioningRepresentation): boolean {
    if (this.activeProvisionings.has(person)) {
      return this.activeProvisionings.get(person);
    }
    return false;
  }

  selectPerson(person: DatabasePersonProvisioningRepresentation) {
    if (this.selectedPersons.indexOf(person) === -1) {
      this.addPerson(person);
    } else {
      this.selectedPersons.splice(this.selectedPersons.indexOf(person), 1);
    }
  };

  addPerson(person: DatabasePersonProvisioningRepresentation) {
    // Only add, if not already added
    if (this.selectedPersons.indexOf(person) === -1) {
      this.selectedPersons.push(person);
    }
  };

  /**
   * Returns true, if a person is selected
   * @param person
   */
  isSelected(person: DatabasePersonProvisioningRepresentation): boolean {
    return this.selectedPersons.some(personSelected=> person.id=== personSelected.id);
  }

  /**
   * Returns true, if there is a result for a given person
   * @param person
   */
  hasResult(person: DatabasePersonProvisioningRepresentation): boolean {
    return this.results.has(person);
  }


  /**
   * Get the result for a given person
   * @param person
   */
  getResult(person: DatabasePersonProvisioningRepresentation): ProvisioningResponse {
    return this.results.get(person);
  }

  /**
   * Select a alarm group
   * @param {} group
   */
  selectAlarmGroup(group: AlarmGroupSimple) {
    if (this.selectedAlarmGroups.includes(group)) {
      this.selectedAlarmGroups.splice(this.selectedAlarmGroups.indexOf(group), 1);
    } else {
      this.selectedAlarmGroups.push(group);
    }
  }

  /**
   * Select all or none element
   */
  selectAllNone() {
    if (!this.allSelected) {
      this.addressbook.content.forEach((person) => {
        if (!this.selectedPersons.some(personSelected=> person.id=== personSelected.id)){
          // only put the person if not already contained
          this.selectedPersons.push(person);
        }
      });
    }
    if(this.allSelected){
      // delete only entries of currently displayed page (unselect all was triggered)
      this.addressbook.content.forEach(person=>{
        // find the index of matching person
        const index = this.selectedPersons.findIndex(personSelected => personSelected.id === person.id);
        this.selectedPersons.splice(index,1);
      })
    }
    // change flag
    this.allSelected = !this.allSelected;
  }

  provision() {
    this.selectedPersons.forEach(person => {
      this.activeProvisionings.set(person, true);
      this.results.delete(person);
      //person._isProvisioning = true;
      this.restService.provision(this.provisioningProfile.id, person.id, false, this.useAutoMode, (data: ProvisioningResponse) => {
        this.$rootScope.$emit('person.' + person.id + '.checkApagerStatus');
        this.activeProvisionings.set(person, false);
        this.results.set(person, data);
      }, (errorResponse) => {
        //Error occured
        this.activeProvisionings.set(person, false);
        this.$log.error(errorResponse);
      });
    });
    this.selectedPersons = [];

    // Alarm groups
    this.isProvisioningGroups = this.selectedAlarmGroups.length > 0;
    this.selectedAlarmGroups.forEach(alarmGroup => {
      this.restService.provision(this.provisioningProfile.id, alarmGroup.id, true, this.useAutoMode, () => {
        this.selectedAlarmGroups.splice(this.selectedAlarmGroups.indexOf(alarmGroup), 1);
        this.isProvisioningGroups = false;
      }, (errorResponse) => {
        this.$log.error(errorResponse);
        this.isProvisioningGroups = false;
      });
    });

  }

  cancel() {
    this.$uibModalInstance.close();
  };
}
