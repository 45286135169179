'use strict';

import { AdminAddUnitRequest, EUnitType } from "../../../../data/unit.data";

require("./admin.add.unit.modal.css")

/* @ngInject */
export default class AdminAddUnitModalController {

    public unitRequest: AdminAddUnitRequest;

    constructor(private $uibModalInstance: any, type: EUnitType,
        public customCode: boolean) {
        this.unitRequest = {
            type,
            name: "",
            code: ""
        } as AdminAddUnitRequest
    }

    public close(): void {
        this.$uibModalInstance.dismiss();
    }

    public save(): void {
        this.$uibModalInstance.close(this.unitRequest);
    }

}