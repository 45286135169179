

export interface ProvisioningSound {
    visible: boolean;
    autoGenerated: boolean;
    file: string;
    name: string;
    matching: boolean;
}

export interface ProvisioningData {
    useFeedback: boolean;
    useFeedbackOverview: boolean;
    useAvailability: boolean;
    useShareAlert: boolean;
    useWasserkarte: boolean;
    osApiKey?: any;
    osId?: any;
    personSharedSecret?: any;
    fe2Mode?: any;
    useSupervisedMode: boolean;
    supervisedPin: string;
    alarmTimeout: number;
    sounds: ProvisioningSound[];
    geoFenceExclusionData: ProvisioningGeoFenceExclusionData[];
}
export interface ProvisioningGeoFenceExclusionData{
  geofenceId: string
}

export interface Provisioning {
    id: string;
    version: number;
    created: number;
    name: string;
    token?: any;
    issuer: string;
    note: string;
    hasPrivacyLink: boolean;
    hasTermOfUses: boolean;
    linksEnforcedByAdmin: boolean;
    data: ProvisioningData;
}

export interface ProvisioningSimple {
    id: string;
    name: string;
    version: number;
    created: number;
}

export interface ProvisioningResponse {
    id: number;
    token: string;
    appliedMode: AutomaticProvisioningAppliedMode;
}

export enum AutomaticProvisioningAppliedMode {
    VIA_PUSH = 'VIA_PUSH',
    FALLBACK_VIA_EMAIL = 'FALLBACK_VIA_EMAIL',
    DID_NOTHING_AS_TYPE_IS_SMS = 'DID_NOTHING_AS_TYPE_IS_SMS',
    FORCE_MODE = 'FORCE_MODE'
}
export interface ProvisioningGeoFenceSelection{
  name: string;
  id: string;
}

export interface TermsOfUse {
    hasPrivacyLink: boolean;
    hasTermOfUses: boolean;
}