'use strict'

import {IRootScopeService, IScope, ILogService} from "angular";
import { AlarmDataSimple } from "../../../data/alarm.data";
import { EmergencyResponse, EmergencyVehicleSimple } from "../../../data/emergency.data";
import {RolePrivilege} from "../../../data/privileges.enum";
import PrivilegeService from "../../../services/privilege.service";
import RestService from "../../../services/rest.service";

require('./missions.view.component.css');

/* @ngInject */
export default class MissionsController {

    public emergencies: EmergencyResponse[];
    public page: number = 0;
    public first = true;
    public last = true;
    public $log:ILogService;

    public isLoading = false;

    private listeners = [];

    constructor(private $scope: IScope,
        private $rootScope: IRootScopeService,
        private $uibModal: any,
        private restService: RestService, $log: ILogService,
        public privilegeService: PrivilegeService) {
        this.$log = $log;
        this.initListeners();
        this.load();
    }


    load() {
        this.isLoading = true;
        this.restService.getAllEmergencies(this.page)
            .then(emergencies => {
                this.page = emergencies.number;
                this.first = emergencies.first;
                this.last = emergencies.last;
                this.emergencies = emergencies.content.filter(emergency => emergency.externalId)
            })
            .then(() => this.isLoading = false)
            .then(() => this.$scope.$applyAsync());
    }

    initListeners() {
      //Wait for new account
      this.listeners.push(this.$rootScope.$on('new.account', (event, account) => {
        this.$log.debug('Missions: new.account triggered!');
        //reload missions on account switch
        this.load();
      }));
      //Wait for LOGOUT
      this.listeners.push(this.$rootScope.$on('delete.account', () => {
        this.$log.debug('Missions: delete.account triggered!');
        this.emergencies = undefined;
      }));
        //Wait for new alarms
        this.listeners.push(this.$rootScope.$on('new.alarm', (event, alarm: AlarmDataSimple) => {
            this.load();
        }));

        // Wait for deletion of an alarm
        this.listeners.push(this.$rootScope.$on('single.alarm.deleted', (event, alarm: AlarmDataSimple) => {
            this.load();
        }));

        // Unregister
        this.$scope.$on('$destroy', () => {
            //Each listener has a unregister function. They are stored in listeners array
            this.listeners.forEach((listener) => {
                listener();
            });
        });
    }

    previousPage() {
        if(!this.first) {
            this.page--;
            this.load();
        }
    }

    nextPage() {
        if(!this.last) {
            this.page++;
            this.load();
        }
    }

    openMission(externalId: string) {
        if (!this.privilegeService.has(RolePrivilege.Home_Emergency_Details)) {
            return;
        }
        this.$uibModal.open({
            template: require('./../../modals/alarms/mission.modal/mission.modal.html'),
            controller: 'MissionModalController',
            controllerAs: 'ctrl',
            backdrop: 'static',
            size: 'hu',
            resolve: {
                externalId: () => externalId
            }
            })
    }

    getVehiclesFormatted(vehicles: EmergencyVehicleSimple[]): string {
        if(vehicles.length == 0) {
            return "";
        }
        if(vehicles.length == 1) {
            return vehicles[0].name;
        }
        if(vehicles.length == 2) {
            return `${vehicles[0].name}, ${vehicles[1].name}`;
        }
        return `${vehicles[0].name}, ${vehicles[1].name} + ${vehicles.length - 2}`;
    }

    parseDate(timestamp: string): Date {
        return new Date(timestamp);
    }
}