import { IScope } from 'angular';
import { IRootScopeService } from 'angular';
import PrivilegeService from '../../../services/privilege.service';
import RestService from '../../../services/rest.service';
import { UserAccount } from '../../../data/account.data';
import { LeaderboardResultEntry } from '../../../data/leaderboard.data';
'use strict';

require('./leaderboard.view.component.scss');

/* @ngInject */
export default class LeaaderboardController {
  public listeners: Function[] = [];
  public account: UserAccount;

  public data: LeaderboardResultEntry[] = [];
  public startMonth: number;
  public endMonth: number;
  public startYear: number;
  public endYear: number;
  public isLoading: boolean = false;

  public sortBy = LeaderboardSortBy.HIGHSCORE;
  public sortOrder = LeaderboardSortOrder.ASC;

  constructor(public $rootScope: IRootScopeService, public $scope: IScope, dataService, public restService: RestService,
    public privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.restService = restService;

    this.listeners = [];
    this.initListeners();

    const now = new Date();
    this.startYear = now.getFullYear();
    this.endYear = this.startYear;
    this.startMonth = 1;
    this.endMonth = now.getMonth() + 1;

    if (dataService.hasAccount()) {
      this.refreshLeaderboard();
    }
  }

  toggleSortOrder(sortBy: LeaderboardSortBy): void {
    if (this.sortBy === sortBy) {
      if (this.sortOrder === LeaderboardSortOrder.ASC) {
        this.sortOrder = LeaderboardSortOrder.DESC;
      } else {
        this.sortOrder = LeaderboardSortOrder.ASC;
      }
    } else {
      this.sortBy = sortBy;
    }
    this.refreshLeaderboard();
  }

  refreshLeaderboard() {
    this.isLoading = true;
    // Construct dates
    let start = new Date();
    start.setFullYear(this.startYear);
    start.setMonth(this.startMonth - 1);
    let end = new Date();
    end.setFullYear(this.endYear);
    end.setMonth(this.endMonth - 1);
    this.restService.loadLeaderboard(start, end, this.sortBy, this.sortOrder).then((response) => {
      this.data = response;
    }).catch((response) => {
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }


  /**
   * Init event listeners
   */
  initListeners() {
    //Wait for new account
    this.listeners.push(this.$rootScope.$on('new.account', () => {
      this.refreshLeaderboard();
    }));

    //Wait for LOGOUT
    this.listeners.push(this.$rootScope.$on('delete.account', () => {
      this.account = undefined;
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }
}

export enum LeaderboardSortOrder {
  DESC = 'DESC', ASC = 'ASC'
}

export enum LeaderboardSortBy {
  NAME = 'NAME', HIGHSCORE = 'HIGHSCORE'
}
