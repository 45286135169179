export interface Vehicle {
    id: string;
    name: string;
    shortName: string;
    code: string;
    licensePlate: string;
    numberOfFaultMessages: number;
    isOverwriteImpulsActivated: boolean;
    overwriteImpuls: number;
    status: EVehicleStatus;
    statusChangedTimestamp: number;
    hasParkingPosition: boolean;
    parkingPosition: ParkingPositionResponse;
    column: number;
    row: number;
    type: string;
    group: string;
    title: string;
    askForStrength: boolean;
    statusColor: string;
    statusTextColor: string;
    statusTranslation: string;
    currentStrength: AssetStrength;
    source: string;
    width: number;
    height: number;
    length: number;
    weight: number;
    vehicleType: EVehicleType;
    hasAlternativeVehicle: boolean;
    alternativeVehicleId: string;
    alternativeVehicle: AlternativeVehicleResponse;
    notAlarmabaleStatus: EVehicleStatus[];
    comingHomeActive: boolean;
    // these are the default values
    cntAssetBattalionLeader: number;
    cntAssetUnitLeader: number;
    cntAssetCrew: number;
    cntAssetBreathingProtection: number;
    connectedBatteryMonitorId: string;
    minVoltage: number;
    maxVoltage: number;
    batteryStatus: BatteryStatus;
    hasIOBatteryWriteAccess:boolean;
}

export interface AlternativeVehicleResponse {
    name: string;
    vehicleId: string;
}

export interface ParkingPositionResponse {
    id: string;
    number: number;
    hasFrontGate: boolean;
    hasBackGate: boolean;
    frontGateName: string;
    backGateName: string;
}

export enum EVehicleStatus {
    STATUS_0 = 'STATUS_0',
    STATUS_1 = 'STATUS_1',
    STATUS_2 = 'STATUS_2',
    STATUS_3 = 'STATUS_3',
    STATUS_4 = 'STATUS_4',
    STATUS_5 = 'STATUS_5',
    STATUS_6 = 'STATUS_6',
    STATUS_7 = 'STATUS_7',
    STATUS_8 = 'STATUS_8',
    STATUS_9 = 'STATUS_9',
    STATUS_97 = 'STATUS_97',
    STATUS_98 = 'STATUS_98',
    STATUS_A = 'STATUS_A',
    STATUS_C = 'STATUS_C',
    STATUS_F = 'STATUS_F',
    STATUS_J = 'STATUS_J',
    STATUS_E = 'STATUS_E',
    STATUS_H = 'STATUS_H',
    STATUS_c = 'STATUS_c',
    STATUS_u = 'STATUS_u',
    STATUS_NOTRUF = 'STATUS_NOTRUF',
    STATUS_UNKNOWN = 'STATUS_UNKNOWN',
    EINSATZAUFTRAG = 'EINSATZAUFTRAG',
    EINRÜCKEN = 'EINRÜCKEN',
    LAGEMELDUNG = 'LAGEMELDUNG',
    AUFMERKSAMKEITSRUF = 'AUFMERKSAMKEITSRUF',
    EIGENSICHERUNG = 'EIGENSICHERUNG',
    MELDEN = 'MELDEN',
    TELEFON = 'TELEFON',
    WACHE_ANFAHREN = 'WACHE_ANFAHREN',
    SPRECHAUFFORDERUNG = 'SPRECHAUFFORDERUNG',
    ENTLASSEN = 'ENTLASSEN',
    SONDERRECHTE_ZUGELASSEN = 'SONDERRECHTE_ZUGELASSEN',
    SIRENE = 'SIRENE',
    ABGESTELLT = 'ABGESTELLT',
    POSITIV = 'POSITIV',
    STANDORT = 'STANDORT',
    NEGATIV = 'NEGATIV',
    GERÄT_ÜBERPRÜFEN = 'GERÄT_ÜBERPRÜFEN'
}

export interface VehiclesForTableau {
    all: Vehicle[];
    STATUS_1: Vehicle[];
    STATUS_2: Vehicle[];
    STATUS_3: Vehicle[];
    STATUS_4: Vehicle[];
    STATUS_6: Vehicle[];
    misc: Vehicle[];
}
export interface AddVehicleRequest{
  name: string,
  code: string
}

export interface PutVehicleAccessRequest{
  userId: string
}

export interface VehicleStatusHistoryResponse {
    data: VehicleStatusHistory;
}

export interface VehicleStatusHistory {
    data: Array<StatusEntryResponse>;
    currentPage: number;
    length: number;
    hasNext: boolean;
    hasPrevious: boolean;
}

export interface StatusEntryResponse {
    status: EVehicleStatus;
    vehicleId: string;
    vehicleName: string;
    address: string;
    timestamp: number;
    source: string;
    color: string;
    textColor: string;
    translation: string;
    technicalStatus: boolean;
    statusToDisplay: boolean;
    vehicleExists: boolean;
    strength: AssetStrength;
}

export interface AssetStrength {
    cntBattalionLeader: number;
    cntUnitLeader: number;
    cntCrew: number;
    cntBreathingProtection: number;
}

export interface StatusGrid {
    rows: Row[];
}

export interface Row {
    row: number;
    columns: Column[];
}

export interface Column {
    column: number;
    values: Vehicle[];
    hasValue: boolean;
}

export enum EVehicleType {
    CAR = 'CAR',
    TRUCK = 'TRUCK'
}

export interface VehiclesPaginatedResponse {
    totalElements: number,
    totalPages: number,
    currentPage: number,
    length: number,
    data: Vehicle[]
}

export interface VehiclesSimplePaginatedResponse {
    totalElements: number,
    totalPages: number,
    currentPage: number,
    length: number,
    data: VehicleSimple[]
}

export interface VehicleSimple {
    id: string;
    name: string;
    shortName: string;
    status: EVehicleStatus;
    statusColor: string;
}

export interface BatteryStatus {
    hasConnectedBatteryMonitor: boolean,
    currentVoltage: number,
    currentVoltageState: VoltageState,
    timestamp: number
}

export enum VoltageState {
    GOOD = 'GOOD',
    TOO_LOW = 'TOO_LOW',
    TOO_HIGH = 'TOO_HIGH',
    UNKNOWN = 'UNKNOWN'
}