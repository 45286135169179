import {EDataBasePersonType, OrganisationPaginatedResponse, PersonSimple, SharePersonRequest, UserSearchResponse} from './../../../../data/person.data';
import {IIntervalService, ILocationService, ILogService, IRootScopeService, IScope} from "angular";
import {UserAccount} from "../../../../data/account.data";
import {EAvailabilityState} from "../../../../data/availability.data";
import {Person} from "../../../../data/person.data";
import {AutomaticProvisioningAppliedMode, ProvisioningResponse, ProvisioningSimple} from "../../../../data/provisioning.data";
import RestService from '../../../../services/rest.service';
import {SortParams} from '../../../views/addressbook.view.component/addressbook.view.component';
import {RoleSimpleResponse} from '../../../../data/role.data';
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";

'use strict';

import angular = require("angular");
import HelperService from '../../../../services/helper.service';

require('./edit.person.modal.css');

/* @ngInject */
export default class EditPersonModalController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $interval: IIntervalService;
  public $location: ILocationService;
  public $translate: any;
  public $timeout: angular.ITimeoutService;
  public $log: ILogService;
  public $uibModal: any;
  public $uibModalInstance: any;
  public restService: RestService;
  public dataService: any;
  public helperService: any;
  public Notification: any;
  public person: Person;
  public tab: number = 0;
  public isSendingTestAlarm: boolean = false;
  public isProvisioning: boolean = false;
  public isProvisioningLoading: boolean = false;
  public isLoadingRoles = false;
  public selectedProvisionProfile: ProvisioningSimple;
  public testAlarmProgress: any;
  public currentaPagerProValue: string;
  public aPagerProValueHasChanged: boolean = false;
  public onlineservice: any;
  public provisionings: ProvisioningSimple[];
  public responseEntry: any;
  public mapping: any;
  public isLoading: boolean = false;
  public isSearching = false;
  public linkToAccessPersonPage: string;
  public hasMailServiceEnabled: boolean = false;
  public mode = PersonModes.DETAILS;
  public listeners = [];
  public account: UserAccount;
  public password: string = '';
  public lstOfFields: string[] = ['ISSI', 'mobil', 'aPagerPro', 'xmpp', 'email'];
  public privileges = [];
  public isValidAPagerProValue: boolean;

  public sharedUsers: UserSearchResponse[] = [];
  public shareIsModified: boolean;
  public personIsShared: boolean;
  public allUsers: OrganisationPaginatedResponse;
  public params: SortParams;
  public openedAsAdmin = false;
  public loginAllowed = false;

  public roles: RoleSimpleResponse[] = [];
  public selectedRole: RoleSimpleResponse;
  public userRoleChanged = false;
  public priv:PrivilegeService;
  public hasPersEdit:boolean= false;
  public hasAlarmGroup:boolean= false;
  public hasProvAssign: boolean= false;
  public hasPersShare:boolean= false;
  public hasProv:boolean= false;
  public hasRoles:boolean= false;
  public hasAddrOnlineS:boolean= false;
  public hasGrAndFuncEdit:boolean= false;
  public hasRolesAssign:boolean= false;
  public hasAvailEdit: boolean= false;
  public isOwnEntry: boolean = false;
  public roleIsBeingRemoved: boolean = false;

  constructor($scope: IScope, $rootScope: IRootScopeService, $timeout: angular.ITimeoutService, $interval: IIntervalService, $location: ILocationService, $translate, $log: ILogService, $uibModal, $uibModalInstance, restService, dataService, helperService, privilegeService: PrivilegeService, Notification, person: PersonSimple, openedAsAdmin?: boolean) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$interval = $interval;
    this.$location = $location;
    this.$translate = $translate;
    this.$timeout = $timeout;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;
    this.dataService = dataService;
    this.helperService = helperService;
    this.Notification = Notification;
    this.openedAsAdmin = openedAsAdmin;
    this.selectedProvisionProfile = undefined;
    this.testAlarmProgress = {
      width: '0%'
    };
    this.priv = privilegeService;

    this.isLoading = true;
    this.restService.loadSinglePerson(person.personID).then(result => {
      this.person = result;
      this.loginAllowed = this.person.loginAllowed;
      this.currentaPagerProValue = this.person.data['aPagerPro'];
      this.init();
      this.personIsShared = this.person.shared || this.person.dataBasePersonType === EDataBasePersonType.SHARED;
      this.isOwnEntry = this.person.personID== this.account.personID;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });


  }

  addToPerson(user: UserSearchResponse) {
    this.shareIsModified = true;
    this.sharedUsers.push(user);
  }

  removeFromPerson(user: UserSearchResponse) {
    const foundElement = this.sharedUsers.filter(entry => entry.userId === user.userId);

    if (foundElement.length > 0) {
      this.shareIsModified = true;
      this.sharedUsers.splice(this.sharedUsers.indexOf(foundElement[0]), 1);
      this.pageChanged();
    }
  }

  pageChanged() {
    if (!(this.account.admin || this.account.personShareFromUser)) {
      // user is not admin and has no right to use share function
      return;
    }
    this.isSearching = true;
    this.restService.getAllUsersPaginated(this.params.currentPage === 0 ? 0 : this.params.currentPage - 1, this.params.pageSize, this.params.searchFilter).then(users => {
      this.allUsers = users;
    }).catch(error => {
      this.$translate(['COMMON.FORBIDDEN']).then((translations) => {
        this.Notification.error({
          message: translations['COMMON.FORBIDDEN'],
          title: error.data.message
        });
      });
    }).finally(() => {
      this.isSearching = false;
      this.updateUI();
      this.$scope.$applyAsync();
      this.$timeout(function () {
        let doc = document.getElementById('edit-person-user-search');
        if (doc) {
          doc.focus();
        }
      });
    });
  }

  userIsSelected(user: UserSearchResponse) {
    return this.sharedUsers.filter(entry => entry.userId === user.userId).length > 0;
  }

  isParentUser(user: UserSearchResponse) {
    return this.person.parent === user.userName;
  }

  updateUI() {
    if (angular.isUndefined(this.allUsers)) {
      return;
    }
    this.params.totalElements = this.allUsers.totalElements;
    this.params.totalPages = this.allUsers.totalPages;
    this.isLoading = false;
  }

  /**
   * Trigger a search
   */
  search() {
    this.params.currentPage = 0;
    this.pageChanged();
  }

  init() {
    // Set color
    this.person.color = this.helperService.getColorForAvailability(this.person.availability);

    //Init
    this.account = this.dataService.getAccount() as UserAccount;
    this.hasPersEdit = this.priv.has(RolePrivilege.Addressbook_Persons_Edit);
    this.hasAlarmGroup = this.priv.has(RolePrivilege.Addressbook_AlarmGroup);
    this.hasProv =this.priv.has(RolePrivilege.Addressbook_Provisioning);
    this.hasProvAssign = this.priv.has(RolePrivilege.Addressbook_Provisioning_Assign);
    this.hasPersShare = this.priv.has(RolePrivilege.Addressbook_Persons_Share);
    this.hasRoles = this.priv.has(RolePrivilege.User_Roles);
    this.hasAddrOnlineS= this.priv.has(RolePrivilege.Addressbook_OnlineService);
    this.hasRolesAssign = this.priv.has(RolePrivilege.User_Roles_Assign);
    this.hasGrAndFuncEdit= this.priv.has(RolePrivilege.Addressbook_Functions_Groups_Edit)
    this.hasAvailEdit= this.priv.has(RolePrivilege.Addressbook_Availability_Edit);
    //Create link
    this.createAccessLink();
    this.hasMailServiceEnabled = this.account.hasMailServiceEnabled;

    if (this.account.hasOnlineService) {
      if (this.hasAddrOnlineS) {
        this.restService.loadAvailabilityForAddressbook(false).then((response) => {
        this.onlineservice = response;
      });
    }
    }
    // validate aPager Pro field on initial loading
    this.validateAPagerPro(this.person.data['aPagerPro']);
    // Load all provisioning profiles
    if (this.hasProv) {
      this.isProvisioningLoading = true;
      this.dataService.getProvisionings(false, (data: ProvisioningSimple[]) => {
        this.isProvisioningLoading = false;
        this.provisionings = data;

        if (this.person.hasProvisioningProfile) {
          const currentId = this.person.provisioningProfile.id;
          const foundProfile = this.provisionings.filter(prov => prov.id === currentId);
          if (foundProfile.length > 0) {
            this.selectedProvisionProfile = foundProfile[0];
          }
        }

        if (data.length > 0 && !this.selectedProvisionProfile) {
          // Select first profile as default
          this.selectedProvisionProfile = data[0];
        }
      }, (err) => {
        this.provisionings = [];
        this.isProvisioningLoading = false;
        this.$log.error(err);
      });
    }

    this.restService.getUsersForPerson(this.person.personID).then((users: UserSearchResponse[]) => {
      this.sharedUsers = users;
    }).catch(err => {
      this.$log.error('Could not load users for person', err);
      this.$translate(['COMMON.UNKNOWN_ERROR', 'COULD_NOT_LOAD_USER_SHARES']).then((translations) => {
        this.Notification.error({
          message: translations['COULD_NOT_LOAD_USER_SHARES'],
          title: translations['COMMON.UNKNOWN_ERROR']
        });
      });
    }).finally(() => {
      this.$scope.$applyAsync();
    });
    if(this.hasRoles) {
      this.loadRoles();
    }

    this.params = {
      searchFilter: '', // set the default search/filter term
      currentPage: 0,
      totalElements: 0,
      pageSize: 20
    } as SortParams;


    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  validateAPagerPro(value: string) {
    // do not show warning on empty field
    if (angular.isUndefined(value) || value === '') {
      this.isValidAPagerProValue = true;
      return;
    }
    // undo if valid was true
    this.isValidAPagerProValue = false;
    this.valuesChanged();
    // check on E-Mail first
    this.validateEMailFormat(value);
    if (this.isValidAPagerProValue) {
      return;
    }
    // validate on phone number if previous validation failed
    this.validatePhoneFormat(value);
  }

  validateEMailFormat(value: string) {
    if (value.includes("@")) {
      // Regex from https://stackoverflow.com/a/46370978
      const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

      if (value.match(regexp)) {
        this.isValidAPagerProValue = true;
        return;
      }
      this.isValidAPagerProValue = false;
    }
  }

  validatePhoneFormat(value: string) {
    if (!value.startsWith("00")) {
      this.isValidAPagerProValue = false;
      return;
    }
    // contains only digit values and starts with double zeros
    if (value.match("^00[0-9]+$")) {
      this.isValidAPagerProValue = true;
      return;
    }

    this.isValidAPagerProValue = false;

  }

  valuesChanged() {
    this.aPagerProValueHasChanged = this.currentaPagerProValue !== this.person.data['aPagerPro'];
  }

  /**
   * Returns true if the person has aPager PRO information
   */
  hasApagerPro() {
    if (!this.person) return false;
    return !(angular.isUndefined(this.person.data['aPagerPro']) || this.person.data['aPagerPro'] === '');
  }

  isApagerProAFieldMobilePhone() {
    // This is a simple check if the aPager PRO field contains a @ sign. If not, the field is treated like a mobile phone number
    var aPagerField = this.person.data['aPagerPro'];
    if (angular.isDefined(aPagerField)) {
      return !aPagerField.includes('@');
    }
    return false;
  }

  close() {
    this.$uibModalInstance.close();
  }

  switchStatusAvailability() {
    this.person.connectStatusWithAvailability = !this.person.connectStatusWithAvailability;
  }

  getFieldIcon(field: string) {
    if (field === "aPagerPro" || field === 'ISSI') {
      return '';
    } else if (field === 'mobil') {
      return 'fal fa-mobile';
    } else if (field === 'email') {
      return 'fal fa-envelope';
    } else if (field === 'xmpp') {
      return 'fal fa-desktop';
    }
    return '';
  }

  sendAlarm(isInfoAlarm: boolean) {
    this.isSendingTestAlarm = true;
    this.testAlarmProgress.width = '0%';
    // timer for 15 seconds
    this.$interval((iteration: number) => {
      this.testAlarmProgress.width = (iteration / 30 * 100) + '%';
    }, 500, 15 * 2);

    this.restService.sendTestAlarm(this.person, isInfoAlarm,
      (response) => {
        this.responseEntry = response.data.entries[0];
        this.$log.debug(this.responseEntry);
        this.mapping = {};
        this.mapping[this.person.data['aPagerPro']] = this.person;
        this.isSendingTestAlarm = false;
        this.testAlarmProgress.width = '0%';
        this.$translate(['PERSON.TEST_APAGER_SUCCESS', 'PERSON.TEST_APAGER_TITLE']).then((translations) => {
          this.Notification.success({
            message: translations['PERSON.TEST_APAGER_SUCCESS'],
            title: translations['PERSON.TEST_APAGER_TITLE']
          });
        });

      },
      (response) => {
        this.testAlarmProgress.width = '0%';
        //Error occured
        this.isSendingTestAlarm = false;
        this.$log.error(response);
      });
  }

  save() {
    this.isLoading = true;
    //Remove property isExpanded

    //in this case person did not have a login before and we generate a new password
    if (this.loginAllowed && !this.person.loginAllowed) {
      this.generatePassword(false);
    }
    this.person.loginAllowed = this.loginAllowed;

    if(!this.person.data.aPagerPro) {
        this.person.sendPasswordViaApager = false;
    }
    if(!this.person.data.email || !this.hasMailServiceEnabled) {
        this.person.sendPasswordViaMail = false;
    }

    if (this.shareIsModified) {
      var userIds: string[] = [];
      this.sharedUsers.forEach(user => {
        userIds.push(user.userId);
      })
      this.restService.updateSharedPersonForUser({ personId: this.person.personID, userIds } as SharePersonRequest).finally(() => {
        this.shareIsModified = false;
        this.savePerson();
      });
    } else {
      this.savePerson();
    }
  }

  private savePerson() {
    this.dataService.savePerson(this.person,
      (response) => {
        this.person.apagerPersonData = response.apagerPersonData;
        this.person.data = response.data;
        this.aPagerProValueHasChanged = false;
        this.person.username = response.username;
        this.person.loginAllowed = response.loginAllowed;
        if (this.person.tmpPassword) {
          this.password = this.person.tmpPassword;
        }
        this.$rootScope.$broadcast("person.saved", this.person);
        this.$rootScope.$broadcast('update.person', this.person);

        if (this.userRoleChanged) {
          if (this.roleIsBeingRemoved || !this.roleIsBeingRemoved && this.selectedRole) {
            //role is either being removed (selected role is undefined) or a role is being added
            //if we do not check this, a error is thrown because backend cant find the role
            this.restService.assignRole(this.person.personID, this.selectedRole ? this.selectedRole.id : undefined).finally(() => {
              this.isLoading = false;
              this.roleIsBeingRemoved = false;
              this.$scope.$applyAsync();
            });
          } else {
            this.isLoading = false;
            this.$scope.$applyAsync();
          }
        } else {
          this.isLoading = false;
          this.$scope.$applyAsync();
        }
      },
      (response) => {
        //Error occured
        this.isLoading = false;
        this.$log.error(response);
        this.$scope.$applyAsync();
      });
  }

  /**
   * Provision the person with the given provisioning profile
   */
  provision() {
    if (angular.isUndefined(this.selectedProvisionProfile)) {
      return;
    }
    this.isProvisioning = true;
    this.restService.provision(this.selectedProvisionProfile.id, this.person.personID, false, false, (result: ProvisioningResponse) => {
      this.isProvisioning = false;

      switch (result.appliedMode) {
        case AutomaticProvisioningAppliedMode.VIA_PUSH:
        case AutomaticProvisioningAppliedMode.FALLBACK_VIA_EMAIL:
        case AutomaticProvisioningAppliedMode.FORCE_MODE:
          this.$translate('AutomaticProvisioningAppliedMode.' + result.appliedMode).then((translation) => {
            this.Notification.success({
              message: translation,
              title: this.person.displayName,
            });
          });

          break;
        case AutomaticProvisioningAppliedMode.DID_NOTHING_AS_TYPE_IS_SMS:
          this.$translate('AutomaticProvisioningAppliedMode.DID_NOTHING_AS_TYPE_IS_SMS').then((translation) => {
            this.Notification.warning({
              message: translation,
              title: this.person.displayName,
            });
          });
          break;
      }
    }, (errorResponse) => {
      //Error occured
      this.isProvisioning = false;
      this.$log.error(errorResponse);
    });

  }

  /**
   * Get the CSS class for provisioning version label
   * @returns
   */
  getClassForLabel() {
    if (!this.person) {
      return 'label-primary';
    }

    if (this.person.apagerPersonData.version === this.person.apagerPersonData.installedVersion) {
      return 'label-success';
    } else if (this.person.apagerPersonData.version > this.person.apagerPersonData.installedVersion && this.person.apagerPersonData.installedVersion !== 0) {
      return 'label-danger';
    }
    return 'label-primary';
  }

  /**
   * Check if person has function
   */
  hasPersonFunction(fu: string) {
    return this.person.functions !== undefined && this.person.functions.indexOf(fu) !== -1;
  }

  hasPrivilege(privilege: RolePrivilege) {
    return this.priv.has(privilege);
  };

  /**
   * Add or remove a function from/to a person
   */
  addRemoveFunctionToPerson(fu: string) {
    if (this.person.functions === undefined) {
      this.person.functions = [];
      this.person.functions.push(fu);
      return;
    }
    var existingIndex = this.person.functions.indexOf(fu);
    if (existingIndex !== -1) {
      this.person.functions.splice(existingIndex, 1);
    } else {
      this.person.functions.push(fu);
    }
  };

  /**
   * Check if person has group
   */
  hasPersonGroup(group: string) {
    return this.person.groups !== undefined && this.person.groups.indexOf(group) !== -1;
  }

  /**
   * Add or remove a group from/to a person
   */
  addRemoveGroupToPerson(group: string) {
    if (this.person.groups === undefined) {
      this.person.groups = [];
      this.person.groups.push(group);
      return;
    }
    var existingIndex = this.person.groups.indexOf(group);
    if (existingIndex !== -1) {
      this.person.groups.splice(existingIndex, 1);
    } else {
      this.person.groups.push(group);
    }
  };

  /**
   * Open the functions and groups overview modal
   */
  openFunctionsGroups() {
    this.$uibModal.open({
      template: require('../../../modals/availability/functions.groups.modal/functions.groups.modal.html'),
      controller: 'FunctionsGroupsModalController',
      controllerAs: 'ctrl',
      size: 'lg'
    });
  };


  /**
   * Set the persons availability status
   */
  setAvailability(availability: EAvailabilityState) {
    this.isLoading = true;
    this.restService.setAvailabilityForPerson(this.person, availability, (response) => {
      this.isLoading = false;
      this.$rootScope.$broadcast('update.person', this.person);
    }, (response) => {
      //Error occured
      this.isLoading = false;
      this.$log.error(response);
    });
  };

  /**
   * Show the link to access a person via external page
   */
  showLinkToAccessPersonPage() {
    this.linkToAccessPersonPage = this.createAccessLink();
  };

  /**
   Change the persons authentificationID
   */
  resetLinkToAccessPersonPage() {
    this.isLoading = true;
    this.restService.resetLinkToAccessPersonPage(this.person, (response) => {
      this.isLoading = false;
      //Update new ID
      this.person.authentificationID = response.data.authentificationID;
      this.createAccessLink();

      this.$translate(['PERSON.RESET_TITLE', 'PERSON.RESET_MESSAGE']).then((translations) => {
        this.Notification.success({
          message: translations['PERSON.RESET_MESSAGE'],
          title: translations['PERSON.RESET_TITLE']
        });
      });
    }, (response) => {
      //Error occured
      this.isLoading = false;
      this.$log.error(response);
    });
  };

  /**
   * Create external access link
   */
  createAccessLink() {

    var external = this.account.external as string;

    // Add port
    if (external.indexOf(':') === -1) {
      // a port is not available, we add it if protocol is not https and port != 443
      if (external.startsWith("https")) {
        if (this.$location.port() != 443) {
          // server is https, but por is not 443 -> add ist
          external = external + ':' + this.$location.port();
        } else {
          // server uses https and port is 443 -> dont need the port
        }
      } else {
        external = external + ':' + this.$location.port();
      }
    }

    // Add protocol
    if (!external.startsWith('http')) {
      // Link does not contain http yet
      external = this.$location.protocol() + '://' + external;
    }

    var link = external + '#!/external/persons?id=' + this.person.personID + '&auth=' + this.person.authentificationID + '&username=' + encodeURIComponent(this.account.username);
    this.linkToAccessPersonPage = link;
    return link;
  }

  /**
   * Send a email to person with the persons link
   */
  sendExternalAccessLinkToPerson() {
    //Create link
    var data = {
      text: this.createAccessLink(),
      recipients: []
    };
    var email = '';
    if (angular.isDefined(this.person.data.email)) {
      email = this.person.data.email;
    }
    if (email === '') {
      if (angular.isDefined(this.person.data.aPagerPro)) {
        email = this.person.data.aPagerPro;
      }
    }
    if (email === '') {
      //No Email
      this.$translate(['USERS.MAIL_TITLE_ERROR', 'USERS.MAIL_MESSAGE_ERROR']).then((translations) => {
        this.Notification.error({
          message: translations['USERS.MAIL_MESSAGE_ERROR'],
          title: translations['USERS.MAIL_TITLE_ERROR']
        });
      });
      return;
    }
    data.recipients.push(email);
    this.isLoading = true;
    this.restService.sendEmailToPerson(data, () => {
      this.isLoading = false;

      this.$translate(['USERS.MAIL_TITLE', 'USERS.MAIL_MESSAGE']).then((translations) => {
        this.Notification.success({
          message: translations['USERS.MAIL_MESSAGE'],
          title: translations['USERS.MAIL_TITLE']
        });
      });
    }, (response) => {
      //Error occured
      this.isLoading = false;
      this.$log.error(response);
    });
  }

  humanReadableDateTime(timestamp) {
    return this.helperService.humanReadableDateTime(timestamp);
  }

  /**
   * Add a person to a group or if the person is already added to the group remove the person
   */
  addToAlarmGroup(alarmGroup) {
    var shouldBeDeleted = false;
    var existingIndex = -1;
    for (var i = 0; i < alarmGroup.personIds.length; i++) {
      if (alarmGroup.personIds[i] === this.person.personID) {
        //Already exists
        existingIndex = i;
        shouldBeDeleted = true;
        break;
      }
    }
    if (shouldBeDeleted) {
      this.$log.debug('Delete person from alarmGroup');
      alarmGroup.personIds.splice(existingIndex, 1);
    } else {
      this.$log.debug('Add person to alarmGroup');
      alarmGroup.personIds.push(this.person.personID);
    }
  }


  /**
   * Checks if the person is in the group
   */
  isInAlarmGroup(alarmGroup) {
    if (angular.isUndefined(alarmGroup)) {
      //Invalid values, nothing to do
      this.$log.warn('Invalid values. Group not defined');
      return false;
    }
    for (var i = 0; i < alarmGroup.personIds.length; i++) {
      if (alarmGroup.personIds[i] === this.person.personID) {
        return true;
      }
    }
    return false;
  }

  toggleEmailNotification() {
    if (!this.hasMailServiceEnabled) {
      return;
    }
    if (!this.person.data.email) {
      // Person has no eMail field
      return;
    }
    this.person.notifications.viaEmail = !this.person.notifications.viaEmail
  }

  toggleAPagerNotification() {
    if (!this.person.data.aPagerPro) {
      // Person has no aPagerPro field
      return;
    }

    this.person.notifications.viaAPager = !this.person.notifications.viaAPager;
  }

  generatePassword(displayInUI: boolean) {
    this.person.tmpPassword = this.helperService.generatePassword(12);
    if (displayInUI) {
      this.password = this.person.tmpPassword;
    }
  }

  reset2FA() {
    this.isLoading = true;
    this.restService.reset2FaForPerson(this.person.username, (data) => {
      this.person = data.data;
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
      this.$log.error(error);
    })
  }

  /**
   * Load all roles
   */
  loadRoles() {
    this.isLoadingRoles = true;
    this.restService.loadRolesSimple().then(roles => {
      this.roles = roles;
    }).finally(() => {
      this.$scope.$applyAsync();
      this.isLoadingRoles = true;
    });
  }

  assignRole(role: RoleSimpleResponse) {

    if (role === undefined) {
      this.roleIsBeingRemoved = true;
    }

    if (!this.hasPersEdit)return;
    if (!this.hasRolesAssign){
      return;
    }
    if (this.person.personID === this.account.personID){
      // do not allow assignment of new roles for own entry
      return;
    }
    this.selectedRole = role;
    this.person.role = role;
    this.userRoleChanged = true;
  }

  private toggleSendPasswordViaApager(): void {
    if(!this.person.data.aPagerPro) return;
    this.person.sendPasswordViaApager = !this.person.sendPasswordViaApager;

  }
  private toggleSendPasswordViaMail(): void {
    if(!this.person.data.email || !this.hasMailServiceEnabled) return;
    this.person.sendPasswordViaMail = !this.person.sendPasswordViaMail;
  }

}

export enum PersonModes {
  DETAILS = 'DETAILS',
  ACCESS = 'ACCESS',
  OS = 'OS',
  NOTIFICATION = 'NOTIFICATION',
  PERMISSIONS = 'PERMISSIONS',
  SHARE = 'SHARE',
  ALARM_GROUPS = 'ALARM_GROUPS',
  SUBSTITUTES = 'SUBSTITUTES',
  PROPERTIES = 'PROPERTIES'
}
