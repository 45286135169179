'use strict';

import { ILogService, IScope } from "angular";
import { AlarmSplitterConfig, SimpleUnit, SplitterData } from "../../../../../data/admin.data";
import RestService from "../../../../../services/rest.service";

require("./admin.splitter.component.css");

export default class AdminSplitterSettingsComponent {
    public restrict: string;
    public template: any;
    public scope: any;
    public controller: any;
    public controllerAs: string;
    public bindToController: boolean;

    constructor() {
        this.restrict = 'EA';
        this.template = require('./admin.splitter.component.html');
        this.scope = {
        };
        this.controller = AdminSplitterComponentController;
        this.controllerAs = 'ctrl';
        this.bindToController = true;
    }
}

/* @ngInject */
class AdminSplitterComponentController {

    public isLoading: boolean = false;
    public isSaving: boolean = false;
    public config: AlarmSplitterConfig;

    private units: any = {};

    constructor(private $scope: IScope,
        private $log: ILogService,
        private restService: RestService,
        private Notification: any,
        private $translate: any,
        private $uibModal: any) {
        this.load();
    }

    load() {
        this.isLoading = true;
        this.restService.loadAllUnits()
            .then((units: SimpleUnit[]) => {
                for (let unit of units) {
                    this.units[unit.code] = unit;
                }
                this.restService.loadAlarmSplitters()
                    .then(alarmSplitter => {
                        this.config = alarmSplitter;
                    })
                    .catch(err => this.$log.error(err))
                    .finally(() => {
                        this.isLoading = false;
                        this.$scope.$applyAsync()
                    });
            });

    }

    add() {
      let unitList: SimpleUnit[] = Object.values(this.units) as SimpleUnit[];
        if (unitList.length < 3) {
            this.$translate(["ADMIN.SETTINGS.SPLITTER.NOT_ENOUGH_UNITS"]).then(translations => {
                this.Notification.warning({
                    title: "",
                    message: translations["ADMIN.SETTINGS.SPLITTER.NOT_ENOUGH_UNITS"]
                });
            })
            return;
        }
        const newSplitter = {
            codeFor: unitList[0].code,
            codeFrom: unitList[1].code,
            codeTo: unitList[2].code
        }
        let modalInstance = this.openEditModal(newSplitter, true);

        modalInstance.result.then((res: SplitterData) => {
            if (res) {
                this.config.splitters.push(res);
                this.$scope.$applyAsync();
            }
        })

    }

    edit(splitter: SplitterData) {
        this.openEditModal(splitter, false);
    }

    private openEditModal(splitter: SplitterData, isNew: boolean) {
        return this.$uibModal.open({
            template: require('../../../../modals/admin/edit.splitter.modal/edit.splitter.modal.html'),
            controller: 'EditSplitterModalController',
            controllerAs: 'ctrl',
            size: 'lg',
            backdrop: 'static',
            resolve: {
                units: () => this.units,
                splitter: () => splitter,
                isNew: () => isNew
            }
        });
    }

    getUnit(code: string): SimpleUnit {
        return this.units[code];
    }

    getUnitNameWithParent(code: string): string {
        let unit: SimpleUnit = this.getUnit(code);
        return `${unit.name} (${unit.parentName})`;
    }

    remove(splitter: SplitterData) {
        this.config.splitters = this.config.splitters.filter(element => element !== splitter);
        console.log(this.config.splitters);

        this.$scope.$apply();
    }

    save() {
        this.isSaving = true;
        this.restService.saveAlarmSplitters(this.config)
            .catch(err => {
                this.$log.error(err)
                this.$translate(['COMMON.INVALID_ARGUMENTS']).then((translations) => {
                    this.Notification.error({
                        message: err.data.message,
                        title: translations['COMMON.INVALID_ARGUMENTS']
                    });
                });
            })
            .finally(() => {
                this.isSaving = false;
                this.$scope.$applyAsync();
            })
    }


}