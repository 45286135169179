'use strict';

import { ILogService, IRootScopeService, IScope, IWindowService } from "angular";
import angular = require("angular");
import { AccountResponse, UserAccount } from "../../../../data/account.data";
import { PluginCategory } from "../../../../data/plugin.data";
import { RolePrivilege } from "../../../../data/privileges.enum";
import { LogLevel, LogObjectEntryTransferable, LogObjectTransferable } from "../../../../data/tracelog.data";
import { Unit } from "../../../../data/unit.data";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";
import { CategoryHolder, PluginTemplate } from "./edit.unit.modal.data";

require('./edit.unit.modal.css');

/* @ngInject */
export default class EditUnitModalController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public $window: IWindowService;
  public $http: any;
  public $uibModal: any;
  public $uibModalInstance: any;
  public $translate: any;
  public $filter: any;
  public fileUploader: any;
  public restService: RestService;
  public dataService: any;
  public unit: Unit;
  public isLoadingTemplates: boolean = false;
  public isSavingPipeline: boolean = false;
  public showingPluginTemplates: any;
  public searchValue: string = '';
  public editNameActive: boolean = false;
  public oldUnitName: string = '';
  public category: any;
  public account: UserAccount;
  public listeners: any;
  public filteredTemplates: any;
  public treeOptions: any;
  public isDirty: boolean = false;
  public pipeline: any;
  public validationResponse: any;
  public plugins: any;
  public pluginTemplates: PluginTemplate[] = [];
  public plugin: any;
  public pipelineTemplates: any;
  public isLoading: boolean = false;
  public traceLogs: LogObjectTransferable[] = [];
  public traceLogsEntries: Map<string, LogObjectEntryTransferable[]> = new Map<string, LogObjectEntryTransferable[]>();
  public selectedTraceLog: LogObjectTransferable;
  public hasEditRight: boolean = false;
  public hasViewRight: boolean = false;
  public isLoadingPlugins = false;

  constructor($scope: IScope, $rootScope: IRootScopeService,
    $log: ILogService,
    $window: IWindowService, $http, $uibModal, $uibModalInstance, $translate, $filter, FileUploader,
    restService: RestService, public privilegeService: PrivilegeService,
    dataService, unit: Unit, pipeline) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$window = $window;
    this.$http = $http;
    this.$uibModal = $uibModal;
    this.$uibModalInstance = $uibModalInstance;
    this.$translate = $translate;
    this.$filter = $filter;
    this.fileUploader = new FileUploader();
    this.restService = restService;
    this.dataService = dataService;
    this.unit = unit;

    this.updatePipeline(pipeline);

    this.hasEditRight = this.privilegeService.has(RolePrivilege.Units_Edit);
    this.hasViewRight = this.privilegeService.has(RolePrivilege.Units_Plugindetails);

    this.category = {
      name: PluginCategory.RECOMMENDED,
      plugins: []
    } as CategoryHolder;
    this.account = dataService.getAccount();
    this.listeners = [];

    this.loadPlugins();
    this.initListeners();

    /**
     * Object, which is referenced by the ui tree
     */
    this.treeOptions = {
      accept: (sourceNodeScope, destNodesScope, destIndex) => {
        //Trigger is dirty flag and accept drop
        this.isDirty = true;
        return true;
      }
    }

    this.getTraceLogsForUnit();

  }

  setNodeDisabled(plugin, isDisabled: boolean): void {
    if (!this.hasEditRight) {
      return;
    }
    plugin.disabled = isDisabled;
    this.isDirty = true;
  }

  updatePipeline(pipeline) {
    this.pipeline = pipeline.nodes;
    this.validationResponse = pipeline.validationResponse;
  }

  /*
      Load the saved units from the server
    */
  loadPlugins() {

    if (!this.hasEditRight) {
      return;
    }
    this.isLoadingPlugins = true;
    this.dataService.getPlugins(false, (plugins) => {
      this.isLoadingPlugins = false;
      this.plugins = this.sortPluginCategoryByTranslation(plugins);
      this.category.plugins = this.plugins[this.category.name];
    }, (response) => {
      //Error occured
      this.isLoadingPlugins = false;
      this.$log.error(response);
    });
  };

  sortPluginCategoryByTranslation(plugins) {
    var result = {};
    var translated = [];
    angular.forEach(plugins, (value, key) => {
      translated.push({
        key: value,
        label: this.$translate.instant('CATEGORY.' + key),
        origValue: key
      });
    });

    angular.forEach(this.$filter('orderBy')(translated, 'label'), (translatedObject) => {
      result[translatedObject.origValue] = [];
      for (var i = 0; i < translatedObject.key.length; i++) {
        result[translatedObject.origValue].push(translatedObject.key[i]);
      }
    });
    return result;
  }

  searchForPlugin() {
    //Iterate categories

    //Search in Plugins
    if (!this.showingPluginTemplates) {

      if (this.searchValue === "" || this.searchValue === null || this.searchValue === undefined) {
        this.category.plugins = this.plugins[this.category.name];
      } else {
        this.category.plugins = [];
        angular.forEach(this.plugins, (value, key) => {

          //var category = this.plugins[key];
          for (var i = 0; i < value.length; i++) {
            if (value[i].name.toLowerCase().includes(this.searchValue.toLowerCase())) {
              this.$log.log(value[i].name);
              this.category.plugins.push(value[i]);
            }
          }
        });
      }
    } else {
      //Search in templates
      if (this.searchValue === "" || this.searchValue === null || this.searchValue === undefined) {
        this.filteredTemplates = this.pluginTemplates;
      } else {
        this.filteredTemplates = [];
        angular.forEach(this.pluginTemplates, (value, key) => {
          if (value.template.name.toLowerCase().includes(this.searchValue.toLowerCase())) {
            this.$log.log(value.template.name);
            this.filteredTemplates.push(value);
          }
        });
      }
    }
  }

  /**
    Add new plugin to pipeline
  */
  addPlugin(plugin) {
    //Copy
    var copy = {};
    copy = angular.copy(plugin, copy);
    copy['nodes'] = [];
    this.pipeline.push(copy);
    //Needs to be saved
    this.isDirty = true;
  };

  /**
    Remove the selected node
  */
  removePlugin(scope) {
    if (!this.hasEditRight) {
      return;
    }
    scope.remove();
    //Needs to be saved
    this.isDirty = true;
  }

  getTraceLogsForUnit() {
    if (!this.privilegeService.has(RolePrivilege.Units_Tracing)) {
      return;
    }
    this.restService.getTraceLogForUnit(this.unit).then(data => {
      this.traceLogs = data;
      this.$scope.$applyAsync();
    });
  }

  getLogsForAlarm(pipe, traceLog: LogObjectTransferable) {
    this.traceLogsEntries = new Map<string, LogObjectEntryTransferable[]>();
    this.selectedTraceLog = traceLog;
    for (var i = 0; i < pipe.length; i++) {
      var pluginNode = pipe[i];
      if (pluginNode.nodes !== undefined && pluginNode.nodes.length !== 0) {
        this.getLogsForAlarm(pluginNode.nodes, traceLog);
      }
      this.getEntryForSelectedAlarmTraceAndPlugin(pluginNode, traceLog.alarmId)
    }
  }


  getEntryForSelectedAlarmTraceAndPlugin(pluginNode, alarmId) {
    this.restService.getEntryForSelectedAlarmTraceAndPlugin(pluginNode, alarmId).then(data => {
      this.traceLogsEntries.set(pluginNode.uuid, data);
      this.$scope.$applyAsync();
    })
  }

  pluginHasLogEntries(node) {
    var hasEntries = this.traceLogsEntries.get(node.uuid)?.length > 0;
    return hasEntries;
  }

  pluginHasErrorEntry(node) {
    return this.traceLogsEntries.get(node.uuid)?.filter(entry => entry.level === LogLevel.ERROR).length > 0
  }
  pluginHasWarningEntry(node) {
    return this.traceLogsEntries.get(node.uuid)?.filter(entry => entry.level === LogLevel.WARNING).length > 0 && !this.pluginHasErrorEntry(node)
  }

  pluginTraceOk(node) {
    return this.pluginHasLogEntries(node) && this.traceLogsEntries.get(node.uuid)?.filter(entry => entry.level === LogLevel.WARNING || entry.level === LogLevel.ERROR).length === 0
  }

  showEntriesForPlugin(node) {
    var entries = this.traceLogsEntries.get(node.uuid);
    this.$uibModal.open({
      template: require('../../../modals/pipeline/tracelog.plugin.modal/tracelog.plugin.modal.html'),
      controller: 'TracelogPluginModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'md',
      resolve: {
        traceLog: () => {
          return entries;
        }
      }
    });
  }

  /**
    Edit the selected node
  */
  editPlugin(plugin) {

    if (!this.privilegeService.has(RolePrivilege.Units_Plugindetails)) {
      return;
    }
    this.plugin = plugin;
    this.$uibModal.open({
      template: require('../../../modals/pipeline/edit.plugin.modal/edit.plugin.modal.html'),
      controller: 'EditPluginModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        plugin: () => {
          var tmp = plugin;
          return tmp;
        },
        pipeline: () => {
          return this.pipeline;
        },
        unit: () => {
          return this.unit;
        },
        isAdmin: () => {
          return this.account.admin;
        }
      }
    })
    //Needs to be saved only of User has Edit right and plugin is not a template
    if (this.hasEditRight && !(angular.isDefined(this.plugin.templateId) && this.plugin.templateId > 0)) {
      this.isDirty = true;
    }
  }

  cancel() {
    if (!this.privilegeService.has(RolePrivilege.Units_Edit)) {
      this.$uibModalInstance.close();
      return;
    }
    if (this.isDirty) {
      this.$uibModal.open({
        template: require('../../../modals/pipeline/is.dirty.modal/is.dirty.modal.html'),
        controller: 'IsDirtyInstanceController',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'sm',
        resolve: {
          okFunction: () => {
            return () => {
              this.$uibModalInstance.close();
            };
          }
        }
      })
    } else {
      this.$uibModalInstance.close();
    }
  }

  /*
    Load the pipeline from server
  */
  savePipeline() {
    this.$log.info('Saving pipeline...');
    this.isSavingPipeline = true;

    this.restService.savePipeline(this.unit.id, this.pipeline, false, (response) => {
      this.isSavingPipeline = false;
      this.isDirty = false;
      this.unit.hasQuickEdit = response.quickEditActive;
      this.updatePipeline(response);
      this.$scope.$emit('unit.pipeline.saved');
    }, (errorResponse) => {
      //Error occured
      this.isSavingPipeline = false;
      this.$log.error(errorResponse);
    });
  }

  getIcon(plugin) {
    let iconCss = 'icon-' + plugin.abstractName.replace(/.*\./, '');
    if (plugin.deprecated) {
      iconCss = iconCss + ' plugin-icon-deprecated';
    }
    return iconCss;
  }

  /**
    Change to selected category
  */
  changeCategory(category: PluginCategory) {
    if (angular.isUndefined(category) || angular.isUndefined(this.plugins[category])) {
      return;
    }
    this.searchValue = "";
    this.category.plugins = this.plugins[category];
    this.category.name = category;
    this.showingPluginTemplates = false;
  };

  showExistingPlugins() {
    this.changeCategory(this.category.name);
  }

  /**
    Load all availabile plugin templates
  */
  loadPluginTemplates(onlyUpdate: boolean) {
    this.isLoadingTemplates = true;

    this.dataService.getPluginTemplates(false, (templates) => {
      this.isLoadingTemplates = false;
      this.pluginTemplates = templates;
      this.filteredTemplates = this.pluginTemplates;
      this.$log.debug(this.pluginTemplates);
      if (onlyUpdate) {
        return;
      }
      this.showingPluginTemplates = true;
      this.searchValue = ""
      //Reset category
      this.category.plugins = [];
    }, (errorResponse) => {
      //Error occured
      this.isLoadingTemplates = false;
      this.$log.error(errorResponse);
    });
  };

  /**
    Edit the selected plugin template
  */
  editPluginTemplate(plugin: PluginTemplate) {
    if (plugin.shared) {
      // Don't allow to edit shared plugins
      return;
    }

    if (!this.privilegeService.has(RolePrivilege.Plugin_Templates_Edit)) {
      return;
    }


    this.plugin = plugin.template;
    this.$uibModal.open({
      template: require('../../../modals/pipeline/edit.plugin.modal/edit.plugin.modal.html'),
      controller: 'EditPluginModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        plugin: () => {
          var tmp = plugin.template;
          return tmp;
        },
        pipeline: () => {
          return this.pipeline;
        },
        unit: () => {
          return this.unit;
        },
        isAdmin: () => {
          return this.account.admin;
        }
      }
    });
  }

  checkPipelineForDeletedPluginTemplates(pipe, deletedPluginTemplate) {
    for (var i = 0; i < pipe.length; i++) {
      var plugin = pipe[i];
      if (plugin.nodes !== undefined && plugin.nodes.length !== 0) {
        this.checkPipelineForDeletedPluginTemplates(plugin.nodes, deletedPluginTemplate);
      }
      if (plugin.templateId === deletedPluginTemplate.id) {
        plugin.templateId = 0;
      }
    }
  };


  /**
    Add a plugin template as a plugin
  */
  addPluginTemplateToPipeline(template) {
    if (angular.isUndefined(template)) {
      return;
    }

    this.dataService.addPluginTemplateToPipeline(template, (plugin) => {
      plugin.nodes = [];
      this.pipeline.push(plugin);
      //Needs to be saved
      this.isDirty = true;
    }, (errorResponse) => {
      //Error occured
      this.$log.error(errorResponse);
    });
  };

  getNameToTemplateId(id: string) {
    for (var i = 0; i < this.pluginTemplates.length; i++) {
      if (this.pluginTemplates[i].template.id === id) {
        return this.pluginTemplates[i].template.description;
      }
    }
    return id;
  }

  //#############
  //TEMPLATES
  //#############

  /**
    Load all availabile pipeline templates
  */
  loadPipelineTemplates(openModal: boolean) {

    this.dataService.getPipelineTemplates(false, (templates) => {
      this.pipelineTemplates = templates;
      this.$log.debug(this.pipelineTemplates);

      if (openModal) {
        var instance = this.$uibModal.open({
          template: require('../../../modals/pipeline/pipeline.templates.modal/pipeline.templates.modal.html'),
          controller: 'ModalGetPipelineTemplatesInstanceController',
          controllerAs: 'ctrl',
          size: 'lg',
          resolve: {
            pipelineTemplates: () => {
              return this.pipelineTemplates;
            }
          }
        });
        instance.result.then((selectedTemplate) => {
          this.getPipelineTemplateById(selectedTemplate);
        }, () => {
          // Nothing to do
        });
      }
    }, (errorResponse) => {
      //Error occured
      this.$log.error(errorResponse);
    })
  }

  /**
    Add a new pipeline template
  */
  addPipelineTemplate() {
    this.$uibModal.open({
      template: require('../../../modals/pipeline/save.pipeline.template.modal/save.pipeline.template.modal.html'),
      controller: 'ModalSavePipelineTemplateInstanceController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        pipeline: () => {
          return this.pipeline;
        }
      }
    })
  }

  /**
    Get pipeline template by id
  */
  getPipelineTemplateById(template) {
    if (angular.isUndefined(template)) {
      return;
    }

    this.dataService.getPipelineTemplateById(template, (pipeline) => {
      this.pipeline = pipeline;
      this.$log.debug(this.pipeline);
    }, (errorResponse) => {
      //Error occured
      this.$log.error(errorResponse);
    });
  }

  /**
    Delete an existing pipeline template by id
  */
  deletePipelineTemplateById(template) {
    if (angular.isUndefined(template)) {
      return;
    }

    this.dataService.deletePipelineTemplateById(template, (templates) => {
      this.pipelineTemplates = templates;
    }, (errorResponse) => {
      //Error occured
      this.$log.error(errorResponse);
    });
  }

  helpPipeline() {
    this.$log.debug('Showing help pipeline dialog');
    //Show help modal
    this.$uibModal.open({
      template: require('../../../modals/pipeline/help.modal/help.modal.html'),
      controller: 'ModalShowHelpController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        urlToWiki: () => {
          return '/help/pipeline.html';
        }
      }
    });
  }

  /**
    Exportiert die Pipeline als JSON
  */
  export() {
    const url = `${this.restService.getBaseUrl()}/units/pipeline/${encodeURIComponent(this.unit.id)}?download=true&Authorization=${this.$http.defaults.headers.common.Authorization}`;
    this.$window.open(url, '_blank');
  }

  /**
   * Refresh the unit after importing JSON
   */
  loadPipeline() {
    this.isLoading = true;
    this.restService.loadPipeline(this.unit.id, (response) => {
      this.pipeline = response.nodes;
      this.validationResponse = response.validationResponse;
    }, (errorResponse) => {
      //Error occured
      this.isLoading = false;
      this.$log.error(errorResponse);
    });
  }

  /**
    Öffnet ein Modal zum Import einer Pipeline
  */
  import() {
    var modalInstance = this.$uibModal.open({
      template: require('../../../modals/pipeline/import.pipeline.modal/import.pipeline.modal.html'),
      controller: 'ModalImportInstanceController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        uploader: () => {
          return this.fileUploader;
        },
        unit: this.unit
      }
    });

    modalInstance.result.then(() => {
      //Reload unit
      this.loadPipeline();
    });
  };

  initListeners() {
    // Add listener
    this.listeners.push(this.$rootScope.$on('updated.plugin.templates', (event, data) => {
      this.pluginTemplates = data.templates;
      this.filteredTemplates = this.pluginTemplates;
      this.searchValue = '';
      //Check if plugin is template
      this.checkPipelineForDeletedPluginTemplates(this.pipeline, data.template);
    }));

    this.listeners.push(this.$rootScope.$on('pipeline.validated', (event, validatedPipeline) => {
      this.updatePipeline(validatedPipeline);
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  /**
   * Save the units name
   */
  saveUnitName() {
    this.editNameActive = false;
    if (this.unit.name === '') {
      // Name should not be empty
      this.unit.name = this.oldUnitName;
      return;
    }

    this.isLoading = true;
    this.restService.updateUnit(this.unit, () => {
      this.isLoading = false;
      this.oldUnitName = this.unit.name;
    }, (response) => {
      //Error occured
      this.isLoading = false;
      this.$log.error(response);
    });

  }
}
