import { IScope } from "angular";
import { Emergency } from "../../../../../data/emergency.data";

require("./mission.alarm.component.css")
/* @ngInject */
export default class MissionAlarmComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controllerAs: string;
  public bindToController: boolean;
  public controller: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./mission.alarm.component.html');
    this.scope = {
      emergency: '='
    };
    this.controllerAs = 'ctrl';
    this.bindToController = true;
    this.controller = MissionAlarmComponentController;

  }
}

class MissionAlarmComponentController {

  public emergency: Emergency;

  private NO_VALUE: string = "Keine Daten";

  constructor(private $scope: IScope,
    private $translate: any) {
    this.$translate(['MISSIONS.NO_VALUE']).then((translations) => {
      this.NO_VALUE = translations['MISSIONS.NO_VALUE'];
      this.$scope.$applyAsync();
    });
  }

  public valueOrTranslation(value: string) {
    if(value) return value;
    return this.NO_VALUE;
  }

}